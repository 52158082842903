/* /* HEAD CSS  */
@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/poppins/Poppins-Bold.ttf");
}

/* MID CSS */
.apikey11-title-h5 {
  color: #28b594;
}

.apikey11-title-h5,
.apikey11-title-h4 {
  font-size: 40px !important;
  line-height: 48px !important;
  font-family: "DMSans-Bold" !important;
  /* margin-left: 8px !important; */
}

.apikey11-title-h5 {
  margin-left: 8px !important;
}

.apikey11-divider,
.btn-enable-key {
  margin-bottom: 48px !important;
}

.enable-apikeys11-text,
.enable-apikeys11-text .apikey11-label {
  /* color: #141416; */
  font-family: "Poppins-Bold";
  font-size: 12px;
  line-height: 12px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
}

.apikey-field {
  max-width: 636px;
  width: 100%;
}

.apikey11-text-field1 {
  margin-top: 12px;
  margin-bottom: 24px;
}

.apikey11-text-field2 {
  margin-top: 24px;
}

.apikey11-form-control {
  width: 100%;
  min-width: 301px !important;
  margin: 0px !important;
  position: relative;
}

.enable-apikeys11-text .apikey11-label {
  margin-top: 0;
  margin-bottom: 12px;
}

.enable-apikeys11-text {
  margin-bottom: 24px;
}

.ap11-form-group {
  position: relative;
  display: flex;
  align-items: center;
}

.ap11-close-btn {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  margin-left: 12px;
  cursor: pointer;
}

.ap11-close-btn img {
  height: 14px;
  width: 14px;
}

.btn-enable-key-box .btn-enable-key,
.btn-enable-key-box .btn-enable-key:hover {
  border-radius: 0;
  font-family: "DMSans-Bold";
  /* color: #141416; */
  /* border: 1.5px solid ; */
  padding: 6px 11px;
  text-transform: capitalize;
  height: 40px;
  background-color: transparent;
}

.btn-enable-key-box .btn-enable-key img {
  margin-right: 15.66px;
}

/* View activity and notification */
.activity-row {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
}

.activity-container {
  max-width: 1120px;
  width: 100%;
  padding-top: 32px !important;
  margin: auto;
}

.bg-f8f8f {
  background: #f8f8f8;
}

.viewact-search-div {
  position: relative;
  width: 100%;
  max-width: 276px;
  margin-left: auto;
}

.viewact-search-div input {
  border: 1.5px solid #b1b5c3;
  height: 40px;
  padding: 0 16px;
  font-family: "poppins";
}

.viewact-search-div fieldset {
  display: none;
}

.viewact-search-icon {
  position: absolute;
  top: 16px;
  right: 11px;
}

.viewact-search-div .MuiFormControl-root {
  width: 100%;
}

.activity-export {
  max-width: 1056px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity-txt {
  font-family: "DMSans-Bold";
  font-size: 32px;
  line-height: 40px;
}

.activity-export-btn {
  max-width: 138px;
  width: 100%;
}

.activity-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
}

.activity-btn-box .activity-btn {
  border: 1.5px solid #b1b5c3;
  width: 100%;
  min-width: 112px;
  height: 40px;
  color: #23262f;
  font-size: 14px;
  line-height: 16px;
  font-family: "DMSans-Bold";
  border-radius: 0px;
  text-transform: inherit;
}

.activity-right {
  display: flex;
  justify-content: end;
  align-items: center;
}

.activity-export-box {
  display: flex;
  justify-content: end;
  align-items: center;
}

.ac-loading-btn-box .ac-loading-btn {
  width: 100%;
  min-width: 112px;
  height: 40px;
  color: #23262f !important;
  font-size: 14px;
  line-height: 16px;
  font-family: "DMSans-Bold";
  border-radius: 0px;
  text-transform: inherit;
}

.activity-export-box .export-btn {
  background-color: #3772ff;
  width: 100%;
  max-width: 106px;
  height: 40px;
  border-radius: 0px;
  color: #fff;
  text-transform: inherit;
}

.aarow-white-down-img {
  height: 16px;
  width: 16px;
  object-fit: contain;
}

.activity-export-box .export-btn:hover {
  background-color: #3772ff;
  color: #ffff;
}

.activity-mobile-table {
  display: none;
}

.actvity-top-main {
  margin-bottom: 30px;
}

.btn-primary-two-fa {
  /* background: #F84343 !important; */
  color: #fff !important;
  font-family: "DMSans-Bold" !important;
  font-size: 16px !important;
  border-radius: 0 !important;
  padding: 10px 24px !important;
  text-transform: none !important;
  width: 100%;
  max-width: 172px;
}
.btn-primary-two-fa:hover {
  background-color: #f84343 !important;
}
.two-scan-img {
  height: 160px;
  width: 160px;
  object-fit: contain;
  border: 1px dashed #3772ff;
}

.apikey-scan-box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 256px;
  width: 100%;
  height: 320px;
  margin: 0px auto;
  flex-direction: column;
  background: #fcfcfd;
}

.download-btn-box .download-btn {
  border-width: 1.5px;
  border-style: solid;
  /* border: 1.5px solid #B1B5C3; */
  width: 159px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  margin-top: 48px;
  /* color: #23262F; */
  text-transform: inherit;
  font-size: 16px;
  line-height: 16px;
  font-family: "DMSans-Bold";
}

.twofa-passward-field {
  border-radius: 0px !important;
}

.scan-bar-bg {
  /* background-color: #F4F5F6 !important; */
  padding: 86px 0 32px 0;
  margin: 32px 0px;
}

.btn-twofa {
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-fa-email-text {
  font-size: 12px;
  line-height: 20px;
  color: #777e91;
  font-family: Poppins-Regular;
}

.two-fa-card {
  margin-bottom: 112px;
}

.two-fa-disable {
  font-family: Poppins-Bold;
}

.enable-two-fa {
  font-size: 14px !important;
  line-height: 24px !important;
  font-family: Poppins-Medium;
}

.change-password-card {
  margin-bottom: 32px;
}
.btn-change-pass {
  margin-bottom: 40px;
}
/* end */

/* MEDIA QUERY  */
@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1200px) and (max-width: 1535px) {
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
}

@media screen and (min-width: 600px) and (max-width: 899px) {
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .activity-mobile-table {
    display: block;
  }

  .activity-table-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }

  /* .activity-table-main{
        background-color: #FCFCFD;
    } */
  .text-uppercase {
    text-transform: uppercase;
  }

  .activity-bitcon-img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    margin-right: 12px;
  }

  .ac-title-main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ac-title-main {
    font-size: 12px;
    line-height: 12px;
    color: #141416;
    font-family: "poppins-bold";
  }

  .ac-bitcon-text {
    color: #23262f;
    font-family: "poppins-medium";
  }

  .ac-left-text {
    color: #777e91;
  }

  .ac-text-right {
    color: #777e91;
  }

  .ac-divider {
    padding: 32px 0px 0px;
    border: 1px solid #b1b5c3;
  }

  .ac-loading-img {
    height: 16px;
    width: 16px;
    object-fit: contain;
    margin-right: 12px;
  }

  .ac-loading-btn {
    width: 100%;
    max-width: 132px;
  }

  .ac-loading-btn-box {
    max-width: 132px;
    margin: 20px auto 0px;
  }

  .ac-loading-btn-box {
    border: 1.5px solid #b1b5c3;
    width: 100%;
    min-width: 132px;
    height: 40px;
    color: #23262f;
    font-size: 14px;
    line-height: 16px;
    font-family: "DMSans-Bold";
    border-radius: 0px;
    text-transform: inherit;
  }

  .activity-right {
    flex-direction: column;
  }

  .market-butttons-group .market-tabs {
    display: none;
  }

  .viewact-search-div {
    margin-left: 0px;

    max-width: inherit;
  }

  .activity-btn-box .activity-btn {
    max-width: inherit;
  }

  .activity-btn-box {
    margin: 16px 0px 16px 0px;
    width: 100%;
  }

  .ac-mobile-dropdown svg {
    display: none;
  }

  .ac-mobile-dropdown .ac-drop-control {
    min-width: 311px !important;
    height: 48px !important;
    background-color: transparent !important;
    border: 1.5px solid #b1b5c3;
  }

  .market-butttons-group .css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-top: 0px !important;
  }

  .ac-mobile-dropdown {
    margin-bottom: 32px !important;
  }

  .ac-down-arrow {
    right: 17px;
    position: absolute;
    top: 13px;
  }

  .viewact-search-div input {
    height: 48px;
  }

  .view-activity-table {
    display: none;
  }

  .two-fa-email-text {
    display: block;
  }

  .btn-primary-two-fa {
    width: 100%;
    max-width: inherit;
  }
}
