/* .css-1oqqzyl-MuiContainer-root {
    max-width: 1400px !important;
} */

/* footer main-container */
/* @media (min-width: 600px)
{ */
/* .css-txdw66-MuiContainer-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
} */

/* footer second-container */
/* @media (min-width: 600px){
.css-c6eomz-MuiContainer-root {
    padding-left: 20px !important;
    padding-right: 20px !important;
}} */

/* .css-txdw66-MuiContainer-roo{
    padding-left: 0px !important;
    padding-right: 0px !important; */
/* } */

@font-face {
  font-family: DMSans-Bold;
  src: url(../fonts/dm_sans/DMSans-Bold.ttf);
}

@font-face {
  font-family: DMSans-Regular;
  src: url(../fonts/dm_sans/DMSans-Regular.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf);
}
