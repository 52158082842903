.swap-box-main {
    padding: 10px;
}

.swap-box-content {
    align-items: center;
    background-color: #fff;
    border: 1px solid #2cb4c921;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 15px;
}

.input-box .form-lable {
    color: #000;
    font-family: Poppins-Medium;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 10px;
    margin-top: 0;
}

.swap-input-box .form-group .form-control input {
    background-color: initial;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    color: #353945;
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 18px;
    padding: 11px 16px;
}

.form-group .form-control input::placeholder {
    color: #08080a;
    font-size: 14px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    opacity: 1;
}

.excha-card {
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 21px;
}

.swap-box-wrapper {
    position: relative;
}

.full-width .input-box.swap-input-box {
    max-width: 100%;
}
.full-width.swap-box-content {
    flex-wrap: wrap;
}
.upload-wrape {
    width: 100%; display: flex; justify-content: space-between;
}
.upload-wrape .upload-sec {width: 100%; width: calc((100% / 2) - 10px);}
.upload-wrape .upload-pad {border: 1px solid #2cb4c921; border-radius: 10px; position: relative; overflow: hidden; min-height: 150px; display: flex; align-items: center; padding: 10px;}
.upload-wrape .upload-pad input {position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: 3; opacity: 0;}
.upload-wrape .upload-pad img {max-width: 100%; max-height: 100%;}
.upload-wrape .upload-pad .browse {position: absolute; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.25); z-index: 1; display: flex;  justify-content: center;  align-items: center; flex-wrap: wrap; flex-direction: column; }
.upload-wrape .upload-pad .browse span {color: #FFFFFF; font-size: 13px; width: 100%; text-align: center; font-weight: 500;}
.upload-wrape .upload-pad .browse img {width: 30px; fill: white;}
.input-box.swap-input-box.full-width p.form-lable {min-height: 23px;}
.input-box.swap-input-box.full-width p.form-lable span {padding: 0;}

.upload-wrape .upload-pad:hover {cursor: pointer; box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);}
.upload-wrape .upload-pad:hover input {cursor: pointer;}

.upload-wrape .upload-pad.attached .browse{ bottom: -100%; transition: all 0.25s linear 0.25s;}
.upload-wrape .upload-pad.attached:hover .browse{ bottom: 0; background: rgba(0, 0, 0, 0.45); transition: all 0.25s linear 0.25s;}
.right-align {
    text-align: right;
}
/* .upload-wrape .upload-pad:hover .browse {bottom: 0; transition: all 0.15s linear 0.15s;cursor: pointer;} */