.bg-exchange-dash {
  display: flex;
  width: 100%;
  /* background-color: green; */
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);
  /* height: 100vh; */
  overflow: hidden;
}

.dashboard-right-main {
  height: 100vh;
}

.dashboard-containt-main {
  padding: 20px;
  border-radius: 10px 0px 0px 10px;
  background: #e4e5e8;
  overflow: auto;
  height: 100%;
}

.display-row > * {
  padding: 0px 8px;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-family: Poppins-Medium;
  font-size: 20px;
  line-height: 30px;
}
.swap-box-main {
  padding: 10px;
}

.swap-box-content {
  align-items: center;
  background-color: #fff;
  border: 1px solid #2cb4c921;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
}

.input-box .form-lable {
  color: #000;
  font-family: Poppins-Medium;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.swap-input-box .form-group .form-control input {
  background-color: initial;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: #353945;
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 18px;
  padding: 11px 16px;
}

.form-group .form-control input::placeholder {
  color: #08080a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.excha-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 21px;
}

.swap-box-wrapper {
  position: relative;
}

.available-swipe-box {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f7c02e; */
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);
  top: 50%;
  transform: translateY(-50%);
}

.available-swiper-icon {
  width: 25px;
}

.available-token-sec {
  align-items: center;
  /* background-image: linear-gradient(72.47deg,#3fe398 22.16%,#32b0c4 76.47%); */
  background: #32b0c42b;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 0 0 10px 0;
}

.available-token-sec .available-titles,
.available-token-sec .available-data {
  color: #000000;
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);

  border-radius: 4px;
  width: 100%;
  /* max-width: 380px; */
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  padding: 10px 15px;
  text-transform: capitalize;
}

/* ------------------------------------------------- */
.deposit-flex-content {
  display: flex;
  flex-direction: column;
  margin: 0px -20px;
  position: relative;
}

.deposit-content-qr-copy,
.deposit-content-qr-content {
  /* width: 50%;
    flex: 0 0 50%;
    max-width: 50%; */
  padding: 0px 20px;
}

/* 
.deposit-flex-content::after {
    content: '';
    background: #8D99CB;
    height: 100%;
    width: 1px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
} */

.qr-code-box-img {
  text-align: center;
  margin-top: 26px;
}

.qr-code-box-img .qr-code-img {
  height: 130px;
  width: 130px;
  object-fit: contain;
}

.copy-hash-id-flex {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 48px;
}

/* .deposit-content-qr-copy, .deposit-content-qr-content {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0px 20px;
} */

.title-deposit-transaction .inner-title-transaction {
  color: #fff;
  font-family: "nunito-regular";
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 10px;
}

.mb-25px-form {
  margin-bottom: 25px;
}

.title-label-comman-user {
  font-size: 15px !important;
  color: #000 !important;
  font-family: "nunito-regular" !important;
  margin: 0px 0px 10px 0px !important;
}

.edit_profile_field .dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.edit_profile_field .dropdown-box .form-control .dropdown-select {
  border: 1px solid #171d35 !important;
  border-radius: 8px;
}

.edit_profile_field .dropdown-select .MuiSelect-select {
  padding: 6.5px 15px !important;
  font-size: 14px;
  line-height: 27px;
  display: flex;
  color: #6b7280;
  display: flex;
  font-family: "Poppins-Medium";
}

.edit_profile_field .form-group .form-control fieldset {
  border: 0px;
  border-width: 0px;
  border-color: transparent;
}

.form-control-details-auth .custom-auth-user-control {
  width: 100%;
}

.blue-btn-main .blue-btn-content,
.blue-btn-main .blue-btn-content:hover {
  background: #2e60eb;
  border-radius: 8px;
  width: 100%;
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 19px;
  font-family: "nunito-regular";
  min-height: 34px;
  cursor: pointer;
}

.connect-wallet-btn-main .copy-icon-btn .copyicon-img {
  height: 24px;
  width: 24px;
}

.copy-hash-id-flex .connect-wallet-btn-main .copy-icon-btn,
.copy-hash-id-flex .connect-wallet-btn-main .copy-icon-btn:hover {
  border-radius: 8px;
  padding: 8px;
  min-width: inherit;
  margin-left: 10px;
}

.copy-hash-id-flex {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.copy-hash-id-flex .form-control-details-auth {
  width: 100%;
}

.form-group .form-control input,
.form-control-details-auth .icon-position-rel input {
  background-color: initial;
  border: 1px solid #d1d5db !important;
  border-radius: 14px !important;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
  padding: 11.45px 16px !important;
  width: 100%;
}

.swap-box-content .form-group .form-control input {
  border-radius: 0 !important;
}

.transfer-sec {
  margin-top: 20px;
}
.history-btn img {
  width: 25px;
}
.copy-btn-new {
  width: 45px;
  height: 45px;
  background: #32b0c4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 10px;
}
/* ------------------------------------------------- */
.form-control-new fieldset {
  border: 1px solid #d1d5db;
  border-radius: 2px;
}

.dropdown-select .MuiSelect-select {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
}

.crypto-token-form {
  margin-top: 25px;
}
.withdraw-token-form {
  margin-top: 0px;
}
.crypto-token-form-withdraw {
  margin-top: 25px;
}
.crypto-token-form .dropdown-box .form-control .dropdown-select,
.transfer-sec .dropdown-box .form-control .dropdown-select {
  border: 1px solid #d1d5db !important;
  border-radius: 8px;
}

.input-box {
  margin-bottom: 15px;
}
.input-box-panel {
  margin-bottom: 0px;
}
/* .btn-main-primary .withdraw-btn {
    max-width: 500px;
} */

.crypto-token-form .withdraw-main-btn {
  text-align: center;
  margin: 23px 0px 0px 0px;
}
.crypto-token-form .withdraw-main-btn-btn {
  text-align: center;
  margin: 15px 0px 0px 0px;
}

.profile-main {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-main img {
  width: 50px;
  object-fit: cover;
}

.profile-head-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-main .noti-icon {
  filter: invert();
  width: 20px;
}

.chart-sec {
  text-align: center;
  margin: 10px 0;
}

.cus-circle {
  width: 20px;
  height: 20px;
  border: 5px solid #5743be;
  border-radius: 50%;
  background: transparent;
  margin-right: 10px;
}

.job-item {
  display: flex;
  align-items: center;
}

.cus-circle-orange {
  border-color: #ff9066;
}

.cus-circle-sky {
  border-color: skyblue;
}

.job-item {
  margin-bottom: 10px;
}

.job-item .available-data,
.loca-add .available-data {
  color: #000000;
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
}

.chart-sec img {
  width: 130px;
}
.col-mt-15 {
  margin-top: 15px;
}
.agency-wrapper {
  display: flex;
  justify-content: space-between;
}
.agency-left-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #32b0c42b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.agency-left-icon .agency-ic {
  width: 25px;
  filter: invert();
}
.loca-ic-sec .location-ic {
  width: 15px;
  margin-right: 5px;
}
.agency-left {
  display: flex;
  margin-left: 1px;
}
.agency-left-content .agency-title {
  color: #000;
  font-family: Poppins-Bold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}
.loca-sec {
  display: flex;
  align-items: center;
}
.loca-add .available-data span {
  color: gray;
  font-size: 12px;
}
.agency-right .asking-price {
  color: gray;
  font-family: "Poppins-Medium";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 0;
}
.agency-right .asking-price-value {
  color: #000;
  font-family: "Poppins-Medium";
  font-size: 19px;
  letter-spacing: 0;
  line-height: 34px;
  margin-top: 0;
}
.agency-right {
  border-left: 1px solid rgb(128 128 128 / 30%);
  padding: 0 0px 0 20px;
}
.agency-card {
  margin-bottom: 15px;
}
.agency-card-wrapper {
  max-height: 400px;
  overflow: auto;
  padding-right: 10px;
}
/* width */
.agency-card-wrapper::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.agency-card-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.agency-card-wrapper::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.agency-card-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.swap-box-wrapper .swap-input-box .MuiSelect-select {
  padding: 10px 32px 10px 10px;
}
.swap-input-box .MuiInputBase-root {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d1d5db !important;
}
.swap-input-box .form-group .form-control input {
  border-bottom: 0px solid #35394533 !important;
}
.main-title-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-title-sec .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-family: "Poppins-SemiBold";
  margin-bottom: 10px;
}
.swap-input-box {
  max-width: 48%;
  width: 100%;
}
.overlay-section {
  position: fixed;
  right: 0;
  max-width: 100%;
  background: rgb(20 19 19 / 50%);
  top: 0;
  bottom: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 99;
}
.mob-header {
  display: none;
}
.admin-sub-title-main .title {
  font-size: 13px;
  font-weight: 700;
  font-family: Poppins-Medium;
}
.admin-sub-title-main .tab-btn .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #111827;
}
.admin-sub-title-main .hr-line {
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);
}
.typography-flex {
  display: flex;
  justify-content: space-between;
}
.transfer-verify {
  border-bottom: 1px solid blue;
  color: blue;
}
.transfer-verify-verify {
  border-bottom: 0px solid;
  color: green;
}
.tab-panel-box .tab-panel-main {
  padding: 0px;
}
.profile-head-main .dashboard-wallet-total {
  color: green;
  font-family: Poppins-Medium;
  font-size: 20px;
  line-height: 30px;
}

.confirmation-box .confirmation-btn {
  border: none;
  padding: 20px;
}
.confirmation-box .con-btn {
  padding: 5px;
  display: flex;
  justify-content: end;
  border: none;
}
.inp-padding .dropdown-box .MuiSelect-select {
  padding: 10px 32px 10px 10px !important;
}
