.portal-sidebar {
  width: 300px;
  height: 100vh;
  /* border: 1px solid black; */
  box-shadow: 10px 5px 3px lightblue;
}

/* .portal-sidebar .fbc-exchange-logo,
img {
  height: 100px;
  object-fit: contain;
} */

.portal-sidebar .fbc-exchange-menu-bar {
  padding: 5px;
  /* border: 1px solid black; */
  height: 100%;
}
.fbc-exchange-menu-bar .fbc-menu {
  background-color: gray;
  color: black;
  padding: 10px;
  border-radius: 20px;
}
