/* HEAD CSS */
/* define font face */

/* MID CSS */
/* your css */

@font-face {
  font-family: DMSans-medium;
  src: url(../fonts/dm_sans/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans-regular;
  src: url(../fonts/dm_sans/DMSans-Regular.ttf);
}
@font-face {
  font-family: DMSans-Bold;
  src: url(../fonts/dm_sans/DMSans-Bold.ttf);
}
@font-face {
  font-family: poppins-regular;
  src: url(../fonts/poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: poppins-medium;
  src: url(../fonts/poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: poppins-semibold;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: poppins-bold;
  src: url(../fonts/poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: AvenirLTStd-Book;
  src: url(../fonts/avenir/AvenirLTStd-Book.otf);
}

.switches {
  margin: 0px;
  padding: 24px 0px 0px 0px !important;
  font-family: "poppins-bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.profile-fiat-text span {
  font-family: "Poppins-Medium";
  /* color: #23262f; */
}
.switches span {
  font-family: "poppins-regular";
  /* color: #23262f; */
}
.boad {
  color: #28b594 !important;
  border-color: rgba(177, 181, 195, 1) !important;
  text-transform: unset !important;
  font-family: DMSans-Bold !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  border-radius: 0px !important;
  line-height: 16px;
}
.main {
  display: flex;
  justify-content: space-between;
}
.profile-flags img {
  height: 24px;
  margin-right: 12px;
  width: 16px;
  object-fit: contain;
  display: flex;
  align-items: center;
}

.leval2 {
  font-family: Poppins-Bold;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  margin: 40px 0px 24px;
  color: #b1b5c4;
  text-transform: uppercase;
}

.switches1 {
  font-family: "poppins-bold";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 24px 0px;
}

.leval-text {
  font-family: Poppins-Bold;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  margin: 0px 0px 24px;
  color: #b1b5c4;
  text-transform: uppercase;
}

.switche-para {
  font-family: "poppins-regular";
  color: #777e91;
  font-size: 14px;
  line-height: 24px;
  margin: 0px;
}

.items {
  /* margin-top: 110px; */
  max-width: 292px;
  width: 100%;
  margin-bottom: 24px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
}

/* start switch css */
.ncx-switch {
  padding: 0 !important;
}

.ncx-switch [class*="MuiSwitch-thumb"] {
  border-radius: 0;
  height: 12px;
  width: 12px;
  box-shadow: none;
  background: #3772ff;
}

.ncx-switch [class*="MuiSwitch-track"] {
  height: 20px;
  width: 40px;
  border-radius: 0;
  background: #b1b5c3;
  opacity: 1 !important;
}

.ncx-switch .MuiSwitch-switchBase {
  padding: 4px;
}

.ncx-switch input:checked + [class*="MuiSwitch-thumb"] {
  background: #fff;
}

.ncx-switch .Mui-checked + .MuiSwitch-track {
  background: #3772ff !important;
  opacity: 1 !important;
}

.profile-flags p {
  font-family: "poppins-regular";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
  margin: 0px;
}

.profile-dropdown1 {
  display: none;
}
.profile-space {
  padding-top: 40px;
  padding-bottom: 40px;
}
/* YAGNIK COMMENT SELECT ISSUE */

/* .profile-flag-select .MuiSelect-select {
  padding: 12px 16px;
  border: 0;
  background: #f4f5f6;
} */

.profile-flag-select fieldset {
  border: 0 !important;
}

.profile-select {
  width: 100%;
  max-width: 248px !important;
  border-radius: 0px !important;
  height: 48px !important;
  padding: 12px 12px 12px 16px;
}

.profile-divider {
  height: 1px;
  margin: 12px auto !important;
  width: 89% !important;
  background: #b1b5c3;
  opacity: 1;
}
.profile-setting-drop {
  display: none;
}
.feature-text h2 {
  margin-top: 48px;
  margin-bottom: 40px;
  font-family: "poppins-semibold";
  /* color: #23262f; */
}

.setting-btn-box {
  margin-top: 26px;
}

.profile-row {
  display: flex;
  justify-content: space-between;
}
.profile-box {
  margin: auto;
  display: flex;
  color: #141416 !important;
  padding: 110px 0px;
}
.profile-setting {
  margin-right: 0px;
  padding: 0px;
  /* padding-left: 343px; */
}
.profile-setting {
  margin-right: 80px;
  /* padding-left: 343px; */
}

.profile-content {
  border: 0px solid;
  /* height: 769px !important; */
  width: 100%;
  max-width: 762px !important;
  padding: 49.5px 40px 40px 40px;
  background: #fcfcfd;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  margin-right: 0px 339px 0px 715px;
}
.profile-head {
  font-size: 10px;
}
.Profile-drop {
  width: 100%;
  max-width: 248px;
  height: 48px;
  background: #f4f5f6;
}
.profile-dropdown {
  width: 100% !important;
  min-width: 248px !important;
}
.Profile-drop svg {
  display: none;
}

.profile-listbox .Profile-text:last-child .profile-btn {
  margin-bottom: 0 !important;
}
.profile-listbox span {
  font-size: 14px;
  line-height: 16px;
  font-family: DMSans-Bold;
}

.profile-flags {
  width: 100%;
  max-width: 16px;
  display: flex;
  align-items: center;
}
.feature-text h2 {
  margin-top: 48px;
  margin-bottom: 40px;
  font-family: "poppins-semibold";
  /* color: #23262f; */
}
.Profile-text {
  /* height: 41px; */
  padding: 0px !important;
}

.profile-icon {
  margin-right: 13.5px;
  min-width: 0px !important;
}

.profile-listbox {
  padding: 0px !important;
}

.profile-head h1 {
  font-family: Poppins-SemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0px;
}

.profile-head p {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 0px 0px 21.5px;
}

.save {
  border-radius: 0px !important;
  float: right;
  border-radius: 0px !important;
  font-family: DMSans-medium !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 16px 24px !important;
  text-transform: none !important;
  text-transform: capitalize;
}

.profile-divider {
  height: 1px;
  margin: 0px auto 12px !important;
  width: 84%;
  background: #b1b5c3;
}
.profile-list {
  padding: 0px !important;
  margin: 0px !important;
}
/* end css */

/* MEDIA QUERY */
@media screen and (min-width: 1536px) {
}
@media screen and (min-width: 1200px) and (max-width: 1535px) {
}
@media screen and (min-width: 900px) and (max-width: 1199px) {
}
@media screen and (max-width: 899px) {
  .text {
    padding-left: 32px;
  }

  .profile-box {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
  }

  .profile-content {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 600px) and (max-width: 899px) {
  .profile-box {
    flex-direction: column;
    padding-top: 25px;
  }

  /* .items,
  .profile-item {
    max-width: 100%;
  } */
}
@media screen and (min-width: 0px) and (max-width: 599px) {
  .profile-dropdown {
    width: 100% !important;
    max-width: 348px !important;
    margin-top: 0px;
  }

  .profile-select {
    width: 100%;
    max-width: 343px !important;
    border-radius: 0px !important;
    height: 48px !important;
    padding: 12px 12px 12px 16px;
  }

  .feature-text h2 {
    margin: 24px 0px 40px 0px;
  }

  .profile-dropdown1 {
    display: block;
  }

  .profile-boad {
    /* padding-bottom: 44px; */
    padding-bottom: 0px;
  }
  .profile-item {
    min-width: 100%;
    width: 100%;
  }
  .profile-content {
    width: 100%;
    max-width: 100%;
    padding: 0px 0px;
    margin-bottom: 10px;
    border: 0px;
    width: auto;
    max-width: 100% !important;
    padding: 16px;
    height: auto;
  }
  .text {
    padding-left: 32px;
  }

  .items {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
  }

  .profile-head h1 {
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0px;
  }
  .Profile-drop {
    margin-top: 44px;
    width: 100%;
    min-width: 311px;
  }
  .feature-text h2 {
    margin: 24px 0px 40px 0px;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .leval {
    color: #b1b5c4;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    padding: 40px 0px 24px 0px;
    margin: 0px;
  }

  .switche-para {
    color: #777e91;
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
  }

  .profile-head h1 {
    padding-top: 16px;
  }

  .profile-head p {
    margin-bottom: 12px;
  }

  .container {
    /* box-sizing: border-box; */
    padding: 0px;
  }

  .profile-space {
    padding: 0px !important;
    /* padding-top: 16px;
    padding-bottom: 16px; */
  }

  .profile-row {
    flex-direction: column;
  }

  .profile-breadcrumb {
    /* padding: 0px 0px 32px 32px; */
    display: block;
  }

  .profile-home {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    color: #777e91;
  }

  .boad {
    margin-top: 0px !important;
    text-transform: none !important;
  }

  .profile1 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    align-items: center;
    /* color: #353945; */
  }

  .hero-title.MuiBox-root.css-0 {
    /* padding: 16px 32px 16px 32px; */
    font-size: 32px;
    line-height: 40px;
  }

  .leval {
    color: #b1b5c4;
    font-family: "Poppins-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    padding: 40px 0px 24px 0px;
    margin: 0px;
  }

  .switche-para {
    color: #777e91;
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
  }

  /* .profile-setting {
    margin-right: 0px;
    padding: 0px;
  } */

  .text {
    padding-left: 32px;
  }
  .profile-box {
    padding: 16px;
    display: flex;
  }
}

/* Profile */

/* end profile */

/* ---------------------------------------DepositFiat------------------------------------------- */
.deposit-hero-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px 40px 0px;
  width: 100%;
}
.deposit-hero-title {
  font-family: DMSans-Bold;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #23262f;
}

.deposit-typography h5 {
  font-family: poppins-semibold;
  font-weight: 600 !important;
  line-height: 32px !important;
  padding-bottom: 48px;
  color: #23262f;
}

.deposit-row {
  width: 100% !important;
  max-width: 762px !important;
  margin: auto !important;
  display: flex !important;
  justify-content: space-between;
}
.MuiFormControl-root.css-4raban-MuiFormControl-root {
  margin: 0px;
  padding: 0px;
}
.deposit-fiat-select-currency {
  justify-content: center;
  align-items: center;
}
/* YAGNIK COMMENT SELECT ISSUE */

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 12px 14px !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-family: "Poppins-Medium" !important;
} */
.deposit-fiat-dropdown-select {
  border-radius: 0px !important;
}
.deposit-fiat-dropdown Select {
  padding: 12px 14px !important;
}
.deposit-fiat-usdt {
  display: flex;
  align-items: center;
  justify-content: center;
}
.deposit-fiat-usdt .deposit-fiat-amount-subtext {
  font-family: "Poppins-Medium";
  font-size: 16px;
  line-height: 24px;
  color: #23262f;
  margin-bottom: 12px;
}
.deposit-fiat-usdt .deposit-fiat-usdt-text {
  font-family: poppins-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #777e91;
  text-align: center;
  margin-left: 8px;
}

.bycrypto8 {
  background: #fcfcfd;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  padding: 40px;
  margin-right: 94px;
  max-width: 842px;
  width: 100%;
}

.deposit-fiat-select-currency {
  width: 100% !important;
  max-width: 369px !important;
}
.deposit-fiat-select-currency-text {
  font-family: poppins-medium;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #23262f;
  padding-bottom: 12px;
}
.deposit-amount-field {
  margin-top: 48px;
}
.deposit-fiat-amount {
  width: 100%;
  max-width: 656px;
  font-weight: 500;
  font-family: poppins-medium;
  font-size: 14px;
  line-height: 24px;
  color: #23262f;
}
.deposit-text-field {
  line-height: 96px;
  font-size: 96px;
  font-weight: 300;
  letter-spacing: -0.02em;
  font-family: avenir;
  text-align: center;
  border: none !important;
}
.deposit-fiat-button {
  align-items: center;
  display: flex;
  justify-content: center;
}
.deposit-fiat-button .deposit-fiat-btn {
  font-family: DMSans-Bold;
  background-color: #23262f;
  font-weight: 700;
  line-height: 16px;
  font-size: 14px;
  width: 100%;
  max-width: 82px;
  padding: 12px 16px;
  border-radius: 0px;
}
.deposit-fiat-button .deposit-fiat-btns {
  border: 1px solid #b1b5c3;
  border-radius: 0px;
  margin-left: 16px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  font-family: DMSans-Bold;
  color: #141416;
  width: 100%;
  max-width: 88px;
  padding: 12px 16px;
}
.deposit-fiat-button {
  width: 100%;
  max-width: 762px;
  margin: auto;
  align-items: center !important;
  justify-content: center !important;
}
.deposit-fiat-continue {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
}
.deposit-fiat-continue .deposit-fiat-continur-btn {
  border-radius: 0px;
  width: 100%;
  max-width: 184px;
  background-color: #3772ff;
  padding: 16px 56px;
  font-size: 16px;
  line-height: 16px;
  font-family: DMSans-Bold;
  text-transform: capitalize;
}
.deposit-fiat-textfield {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}
.deposit-fiat-textfield .deposit-dollar-sign {
  margin-top: 8px;
  margin-right: 8px;
  font-family: DMSans-Bold;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  color: #23262f;
}
.deposit-fiat-formcontrol {
  width: 100%;
  max-width: 369px;
  margin: auto;
}
.deposit-fiat-icon {
  display: none !important;
}

/* YAGNIK COMMENT SELECT ISSUE */
/* fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
} */
/*----------------------------------- deposit-fiat  MEDIA QUERY ---------------------------------*/
@media screen and (max-width: 899px) {
  .deposit-hero-main {
    display: block;
    padding: 16px 32px 39.78px 32px;
    font-size: 32px;
    line-height: 40px;
    font-family: "DMSans-Bold";
    text-align: center;
  }
  .deposit-hero-title {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    display: flex;
  }
  .deposit-fiat-icon {
    display: flex !important;
    margin: 10px 10px 0px 10px;
    font-size: 23px;
  }
  .bycrypto8 {
    margin: 0px;
    padding: 32px;
    padding-bottom: 72.16px;
  }
  .deposit-typography h5 {
    padding-bottom: 0px;
  }
  /* YAGNIK COMMENT SELECT ISSUE */
  /* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100%;
    max-width: 311px;
  } */
  .deposit-fiat-select-currency-text {
    font-family: poppins-medium;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #23262f;
    padding-top: 32px;
    padding-bottom: 12px;
  }
  .deposit-fiat-select-currency-text.selected.MuiBox-root.css-0 {
    padding-top: 24px;
  }
  .deposit-amount-field {
    margin-top: 32px;
    text-align: center;
  }
  .deposit-fiat-continue {
    margin-top: 32px;
  }
  .deposit-breadcrumb {
    padding-top: 16px;
  }
  .bycrypto-learn-text {
    margin-left: 8px;
    /* color: #353945; */
    font-size: 12px !important;
    line-height: 20px !important;
    font-family: "poppins-medium";
  }
  .deposit-breadcrumbs-text {
    font-size: 12px;
    line-height: 20px;
    font-family: "Poppins-Medium";
    color: #777e91;
  }
  button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.deposit-fiat-btns.box.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    display: none;
  }
  .deposit-fiat-formcontrol {
    width: 100% !important;
    max-width: 100% !important;
  }
  .deposit-hero-breadcrumbs {
    width: 100%;
    max-width: 311px;
    margin: auto;
  }
}
/*----------------------------------- deposit-fiat  MEDIA QUERY ---------------------------------*/
/* -------------------------------------End Depositfiat -----------------------------------------*/
