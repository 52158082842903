/* BNB price css start*/

.bnb-container {
  max-width: 1200px;
  margin: auto;
}

.breadcumb-part {
  margin: 40px 0;
}

.breadcumb-part .MuiBreadcrumbs-separator {
  width: 24px;
  height: 24px;
  justify-content: center;
  font-size: 18px;
  align-items: center;
  color: #707a8a;
}

.breadcumb-part .MuiBreadcrumbs-li {
  font-size: 14px;
  line-height: 1;
  color: #707a8a;
  font-family: Poppins-Regular;
}

.breadcumb-part .MuiBreadcrumbs-li p {
  color: #1e2329;
  transition: all 0.5s;
  font-family: Poppins-Regular;
  font-size: 14px;
}

.breadcumb-part .MuiBreadcrumbs-li p:hover {
  color: #c99400;
}

.bnb-heading {
  display: flex;
  align-items: center;
}

.bnb-heading img {
  margin-right: 24px;
}

.bnb-heading .bnb-token-name {
  color: #1e2329;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
  align-items: center;
}

.bnb-heading .sub-heading {
  color: #1e2329;
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
  align-items: center;
}

.bnb-token-name span {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  color: #707a8a;
  padding-left: 16px;
}

.bnb-heading-select {
  display: flex;
  align-items: center;
  max-width: 768px;
  justify-content: space-between;
  padding-top: 7px;
}

.bnb-select2 {
  display: flex;
  align-items: center;
}

.currency-txt {
  line-height: 20px;
  padding: 12px 28px 12px 0;
  color: #707a8a;
  font-size: 14px;
}

.bnb-select2 .select2-container--default,
.bnb-select2 .select2-container--default .select2-selection {
  width: 183px !important;
  height: 40px !important;
}

.bnb-select2
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 38px !important;
}

.bnb-select2
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 38px !important;
}

.select2-results__option:hover,
.select2-results__option {
  color: #1e2329 !important;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: transparent !important;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: transparent !important;
  color: #c99400 !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 40px !important;
}

.chart-tab {
  margin-top: 16px;
}

.bnb-price-chart {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #1e2329;
  margin-right: 10px;
}

.price-inc-day {
  display: flex;
  align-items: baseline;
  margin-bottom: 24px;
}

/* .main-chart {
  max-width: 800px;
  min-width: 800px;
} */

.main-chart {
  max-width: 800px;
  min-width: 800px;
  flex: 0 0 800px;
}

.bnb-inc-chart {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #0ecb81;
  margin-right: 10px;
}

.bnb-day-chart {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #b7bdc6;
  margin-right: 10px;
}

.days-select-op {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.days-select-op .days-btn {
  max-width: 30px !important;
  min-width: 30px !important;
  height: 24px !important;
  color: #b7bdc6;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 6px !important;
}

.days-btn.selected {
  color: #c99400;
}

.main-chart-graph {
  width: 100%;
}

.chart-tab {
  display: flex;
  align-items: flex-start;
}

.main-tabbar {
  margin-top: 16px;
}

.main-inner-tabs .MuiTabs-flexContainer {
  justify-content: space-around;
}

.main-tabbar {
  width: 100%;
}

.sub-tabs .css-1gsv261 {
  border: none;
}

.sub-tabs .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  border: none;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  /* color: #572c0a; */
  color: #c99627;
}

.sub-tabs .MuiTab-textColorPrimary {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1e2329;
}

.sub-tabs .css-1aquho2-MuiTabs-indicator {
  background-color: #ffd44c !important;
  height: 4px !important;
}

.tab-pane-custom .css-19kzrtu {
  padding: 24px 30px !important;
}

.buy-sell-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1e2329;
}

.buy-sell-box {
  border-radius: 8px;
  padding: 16px;
  background-color: #fafafa;
}

.input-buy-sell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.input-buy-sell > input::placeholder {
  color: #b6bdc5;
}

.input-buy-sell > input:focus-visible {
  border: none;
  outline: none;
}

.input-buy-sell > input {
  width: 160px;
  font-size: 20px;
  line-height: 24px;
  background-color: transparent;
  color: #1e2329;
  border: none;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.buy-logo-name {
  display: flex;
  border-radius: 50px;
  padding: 8px;
  background-color: #ffffff;
}

.buy-coin-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1e2329;
  margin-left: 8px;
  margin-right: 8px;
}

.calc-totl-buy-main {
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-input-buy,
.right-input-buy {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #707a8a;
}

.buy-sell-btn {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  min-width: 80px;
  word-break: keep-all;
  color: #181a20;
  border-radius: 4px;
  min-height: 24px;
  border: none;
  background-image: none;
  background-color: #fcd535;
  margin-bottom: 8px;
  width: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  font-weight: 500;
}

.buy-sell-btn span {
  padding-left: 5px;
}

.update-text {
  font-size: 12px;
  line-height: 16px;
  text-align: end;
  margin-bottom: 24px;
  color: #707a8a;
}

.update-trader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: rgb(250, 250, 250);
  padding: 16px;
}

.trade-inner-main {
  display: flex;
  flex-direction: column;
}

.left-trader,
.right-trader {
  font-size: 14px;
  line-height: 20px;
  color: rgb(30, 35, 41);
}

.left-trader span {
  color: #707a8a;
}

.right-trader span {
  color: rgb(3, 166, 109);
}

.trade-inner-main a {
  text-decoration: none;
  margin-bottom: 16px;
}

.trade-inner-main a:last-child {
  margin-bottom: 0;
}

.market-table-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  height: 100%;
  display: block;
}
.main-chart-set{
  margin-top: 5px;
}
.market-section-title.market-section-title-set p {
  line-height: 20px !important;
  margin-bottom: 20px;
}

/* BNB price css end*/
