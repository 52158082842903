/* HEAD CSS 

define font face  */
/* footer */
@font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-bold;
  src: url("../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-semibold;
  src: url("../fonts/poppins/Poppins-SemiBold.ttf");
}

.poppins-regular {
  font-family: Poppins-Regular !important;
}

.poppins-medium {
  font-family: Poppins-medium !important;
}

/* end footer */

/* header */
@font-face {
  font-family: DMSans-medium;
  src: url(../fonts/dm_sans/DMSans-Medium.ttf);
}

/* end header */

/* market */
@font-face {
  font-family: DMSans-Bold;
  src: url(../fonts/dm_sans/DMSans-Bold.ttf);
}

@font-face {
  font-family: IBM Plex Sans;
  src: url(../fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf);
}

@font-face {
  font-family: IBM Plex Sans Medium;
  src: url(../fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf);
}

@font-face {
  font-family: IBM Plex Sans SemiBold;
  src: url(../fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf);
}

@font-face {
  font-family: IBM Plex Sans Bold;
  src: url(../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf);
}

/* end market */

/*  MID CSS
    

/* ----------------------- COMMON CSS START */
.inline-block {
  display: inline-block;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

/* ----------------------- COMMON CSS END */

/* footer */
.mobile-footer-icon {
  object-fit: contain;
  width: 137px;
  height: 32px;
  margin-bottom: 38px;
}

.poppins-regular {
  font-family: Poppins-Regular !important;
}

.poppins-medium {
  font-family: Poppins-medium !important;
}

/* Footer content */

.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 50px;

  position: relative;
  /* width: 100vw; */
  height: 454px;

  background: #fcfcfd;
}

/* Footer font */
.footer-font {
  position: static;
  /* width: 77px; */
  height: 16px;
  left: 0px;
  top: 0px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #141416;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 24px !important;
}

/* footer container */
/* mobile footer*/

.newsletter-card-input {
  width: 100%;
  border: 1.5px solid #b1b5c3;
  height: 48px;
  padding: 14px 16px;
  box-shadow: none !important;
  outline: none !important;
  background-color: transparent;
}

.newsletter-card-button {
  background: #3772ff;
  border: 0;
  height: 32px;
  width: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.newsletter-card {
  position: relative;
}

.newsletter-card .newsletter-card-button {
  position: absolute;
  display: flex;
  align-items: center;
  top: 8px;
  right: 8px;
}

.divider-end-accordian {
  height: 1px;
  max-width: 100%;
  width: 100%;
  /* background-color: #B1B5C3; */
  margin: 0 16px;
}

.mobile-footer-secondbox {
  margin-top: 32px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 69.5px;
}

.navigation .nav-item {
  margin-right: 48px;
  text-decoration: none;
  /* color: rgb(20, 20, 22); */
  font-style: normal;
  font-size: 15px;
  font-weight: 700;
}

.footer-menu-navlink .footer-nav-link {
  padding-left: 0;
}

.footer-menu-navlink .footer-nav-link a {
  font-size: 14px;
  line-height: 16px;
  color: #141416;
  font-style: normal;
  font-weight: 400;
}

.footer-main-accordion {
  width: 100%;
  padding: 0 16px;
}

.mobile-footer-secondbox p {
  font-family: poppins-medium;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.footer-main-accordion [class*="MuiPaper-root-MuiAccordion-root"]:before {
  display: none;
}

/* end footer */

/* header start */
/* ----------------------------------------------  HEADER ----------------------------------------------------------- */
.language-select .MuiSelect-select {
  padding: 0;
  border: 0 !important;
  font-family: "DMSans-Bold";
  font-size: 14px;
  font-weight: 700;
}

.language-select fieldset {
  border: 0;
  font-family: DMSans-Bold;
}

/* header end */

/* start market */
/* ---------------------------------------------- MARKET ----------------------------------------------------------- */
.market-table-container {
  background-color: inherit !important;
}

.passed-buy-amount {
  height: 100%;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: Poppins-Regular;
}

.buycrypto-selct fieldset,
.buycrypto-selct fieldset:hover {
  /* border:  1px solid #B1B5C3; */
  border-radius: 2px;
  border-width: 1px !important;
  border-color: #b1b5c3 !important;
}

.buycrypto-amount-selct fieldset,
.buycrypto-amount-selct fieldset:hover {
  /* border:  1px solid #B1B5C3; */
  border-radius: 2px;
  border-width: 1px !important;
  border-color: #b1b5c3 !important;
  border-left: transparent;
}

.group-select-box {
  display: flex;
  /* background-color: #FFFFFF; */
  position: relative;
}

.group-select-box svg {
  display: none;
}

.byctypto-value-box {
  width: 100%;
  min-width: 201px;
  border-top: 1px solid #b1b5c3;
  border-bottom: 1px solid #b1b5c3;
  border-left: 1px solid #b1b5c3;
  height: 50px;
}

.by8-dropdown-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: #F4F5F6; */
  padding: 12px 30px 19px 30px;
  box-shadow: 0px 3px 16px rgba(15, 15, 15, 0.1);
}

.buycrypto-amount-selct div {
  /* padding: 6px 15px 8px 0px !important; */
  height: 50px;
  font-size: 14px;
  line-height: 20px;
  font-family: Poppins-Regular;
  min-width: 34px;
}

/* .mr-41{
  margin-right: 41px;
} */
.by8-dropdown-main:last-child {
  margin-right: 41px;
}

.by8-dropdown-main .bycrypto-lable {
  margin-bottom: 9px;
  font-size: 12px;
  line-height: 20px;
  /* color: #000000; */
  font-family: Poppins-Regular;
}

.select-coin-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-right: 6px;
}

.payment-dropdown .payment-dropdown-select > div {
  padding: 14px 0px 13px 21px;
  display: flex;
  /* background: #ffff; */
}

.market-analysis-panel {
  font-family: "IBMPlexSans-Regular";
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  border: 1px solid #fcfcfd;
  box-sizing: border-box;
  padding: 24px 0px;
  justify-content: space-between;
  border: none;
}

.market-box {
  display: flex;
}

.market-box:last-child {
  margin-right: 0px;
}

.market-table {
  /* display: flex;
    align-items: center; */
  min-width: 1150px;
  margin-top: 2px;
}

.market-datatable-logo img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.coin-title {
  margin: 0px;
  font-family: "IBM Plex Sans ";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #23262f;
}

.coin-sub-title {
  color: #777e91;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0px;
}

/* .market-datatable-data {} */

.table-sort-icon {
  /* display: flex; */
  flex-direction: column;
  display: inline-flex;
  margin-left: 8px !important;
}

.market-table-header th {
  color: #777e91;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
  border-bottom: 1px solid #f5f5f5;
}

.market-table-header th button {
  color: #777e91;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
}

.market-table-header {
  height: 102px;
}

.icon-fonts {
  font-size: 12px;
  margin: 0;
}

.market-table-rows {
  /* font-size: 12px; */
  margin: 0;
  height: 73px;
}

.market-table-rows td {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.market-table-rows p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 0px 0px 10px;
}

.market-table-name-column {
  display: inline-block;
}

.market-table-name-column p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.market-table-rows .coin-svg {
  height: 25px;
  width: 25px;
}

/* for row hover desktop */

.market-table-rows button {
  display: none;
}

.market-table-rows:hover {
  background-color: #f4f5f6 !important;
}

.market-table-rows:hover button {
  display: inline-block;
  box-shadow: none;
}

/* .market-table-rows:hover .graph {
  display: none;
} */

.market-table-rows:hover .star span {
  color: #777e91;
}

.market-table-rows .graph {
  min-width: 96px;
}

.market-table-rows td,
th {
  border-bottom: 1px solid #f5f5f5;
}

.market-table-rows td .MuiButton-root {
  padding: 8px 12px;
  width: 80px;
  height: 32px;
  background: #3772ff;
  font-family: "DMSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: none;
  border-radius: 0px;
  color: #ffffff;
  /* identical to box height, or 114% */

  text-align: center;
}

.market-box-logo img {
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.market-box-logo {
  margin-right: 16px;
}

.coin-name {
  color: #777e90;
  font-family: "IBM Plex Sans SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin: 0px 12px 0px 0px;
}

.market-butttons-group .MuiTabs-indicator {
  height: 0px;
}

.coin-progress {
  background-color: #28b594;
  width: 60px;
  font-size: 12px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  color: #fff;
  margin: 0px;
}

.upper-box {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}

.current-price {
  font-family: "IBM Plex Sans SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #23262f;
  margin: 0px 0px 0px;
  white-space: nowrap;
  /* font-family: Poppins-semibold; */
}

.ref-input-box {
  display: flex;
  align-items: center;
  position: relative;

  /* background: #fcfcfd; */
}

.price {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #23262f;
  margin: 4px 0px;
}

/* .market-box-data {} */

.market-box-graph {
  margin-left: 16px;
  width: 102px;
  height: 33.2px;
}

.market-butttons-group {
  padding: 28px 0px 53px;
}

.wallet-btn-box {
  border: 1.5px solid #353945 !important;
  color: #23262f;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 150px;
  height: 40px;
  padding: 12px 53.5px;
}

.market-wallet-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fcfcfd;
  border-top-style: none;
  padding: 0px 32px;
}

.market-wallet-btn {
  border: 1.5px solid;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 9.5px 52.1px;
  margin-bottom: 22px;
  background-color: transparent;
  font-family: "DMSans-Bold";
}

.market-tabs button {
  font-family: "DMSans-Bold";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 16px;
  margin-right: 12px;
  padding: 6px 9px;
  height: auto;
  min-height: auto;
  text-transform: none;
  min-width: auto;
}

.payment-dropdown {
  width: 100%;
  min-width: 277px;
  position: relative;
}

.payment-dropdown svg {
  display: none;
}

.payment-dropdown .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 0px;
}

.select-menuitem-label {
  display: block;
  font-size: 14px;
}

/* ------Market Mobile css------- */
.market-mobile-select {
  padding: 39px 48px 30px 48px;
}

.market-mobile-table-container .flex-space-between p {
  margin: 0px 0px 8px 0px;
}

.market-mobile-table-container .flex-space-between p:first-child {
  font-family: IBM Plex Sans Bold;
  font-size: 12px;
  line-height: 20px;
  color: #777e91;
}

.market-mobile-table-container .flex-space-between p:last-child {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.market-icon-svg {
  display: flex;
  height: 25px;
  width: 25px;
  object-fit: cover;
  margin: 0px 12px 16px 0px;
}

.market-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}

.coin-short {
  margin-left: 4px;
  margin: 0px 0px 0px 4px;
  font-size: 14px;
  line-height: 24px;
}

.coin-name-mobile {
  margin: 0px;
}

.market-mobile-table-container .flex-space-between p {
  margin: 0px;
  height: 29px;
}

.market-mobile-table-container .coin-name-mobile {
  /* margin: 0px 32px; */
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-size: 14px;
  display: inline-block;
  vertical-align: super;
  margin-top: 0px;
  /* margin-bottom: 15px; */
}

.market-mobile-table-container .inline-block .coin-name-mobile {
  margin-left: 12px;
  font-weight: 800;
}

.market-mobile-table-container .inline-block .coin-short {
  /* margin: 0px 32px; */
  font-weight: 400;
  margin-left: 4px;
}

.market-mobile-table-container .inline-block img {
  max-width: 25px;
  max-height: 25px;
}

.market-mobile-table-container .inline-block {
  margin-bottom: 8px;
  margin-top: 24px;
}

.market-mobile-table-container {
  border-bottom: 2px solid;
  padding-bottom: 12px;
  margin-bottom: 24px;
  margin: 24px 32px 0px;
}

.market-mobile-select > div > div {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.market-mobile-select > div {
  width: 279px;
  height: 48px;
}

/* end market */

/* MEDIA QUERY  */
@media screen and (min-width: 1536px) {
  /* for market page */
}

@media screen and (min-width: 1200px) and (max-width: 1535px) {
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
  /* for market page */
  .market-tabs button {
    font-family: "DMSans-Bold";
    font-size: 1vw;
    margin-right: 1px;
    min-width: auto;
  }

  .market-wallet-box {
    flex-direction: column;
  }

  .market-butttons-group {
    padding: 4vw 0px;
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .market-tabs button {
    font-family: "DMSans-Bold";
    font-size: 1vw;
    margin-right: 1px;
    min-width: auto;
  }

  .market-wallet-box {
    flex-direction: column;
  }

  .market-butttons-group {
    padding: 4vw 0px;
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
}
