.trending-coins-text .trending-text {
  margin-left: 60px;
  margin-top: 20px;
  font-weight: inherit;
  font-size: 30px;
}

.exchange-main .trending-coins-card {
  padding: 0px 70px;
  margin-top: 20px;
  /* display: flex; */
  /* gap: 300px; */
  width: 100%;
}
.trending-card-content {
  display: flex;
  width: 100%;
}
.tranding-coin-box {
  width: 350px;
  display: flex;
}
.trending-card-content .card-content-image img {
  width: 50px;
}
.trending-box-data {
  box-sizing: border-box;
  margin: 0 0 0 20px;
  padding: 0;
}

.upper-box {
  align-items: baseline;
  display: flex;
  margin-bottom: 4px;
}

.trending-box-data .coin-name {
  color: #777e90;
  font-family: IBM Plex Sans SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.trending-box-data .current-price {
  color: #23262f;
  font-family: IBM Plex Sans SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;

  white-space: nowrap;
}

.main-tab-container {
  margin: 0px 20px 10px 20px;
}

.main-table-container .main-table {
  min-width: 650px;
  border: 0px;
}

.table-header th {
  color: #777e91;
  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
}
.table-rows td {
  font-family: IBM Plex Sans SemiBold;
}
.table-rows .market-cap {
  color: black;
}
.change24-h-positive {
  color: rgb(40, 181, 148);
}
.change24-h-negative {
  color: red;
}
.table-rows .change24-h-positive {
  color: rgb(40, 181, 148);
}
.table-rows .change24-h-negative {
  color: red;
}

.table-rows .market-cap-loser {
  color: black;
}
.table-rows .change24-h-loser {
  color: red;
}
.table-rows .name-data {
  display: flex;
}

.name-text-data {
  margin-top: 0px;
}
.name-text-data .name-text {
  font-size: 13px;
  margin: 0 0 0 10px;
  font-family: IBM Plex Sans SemiBold;
}
.name-text-data .name-text-2 {
  font-size: 11px;
  margin: 0 0 0 10px;
  font-family: IBM Plex Sans SemiBold;
  color: rgb(119, 126, 145);
}
/* .table-image{
    width: 50px;
} */
.name-data img {
  width: 30px;
  height: 30px;
}

.tab-list button {
  color: #23262f;
}
.tab-list button:hover {
  color: rgb(40, 181, 148);
}
