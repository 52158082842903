/*============================================ HEAD CSS======================================================== */
@font-face {
  font-family: DMSans-Bold;
  src: url(../fonts/dm_sans/DMSans-Bold.ttf);
}

@font-face {
  font-family: DMSans-Regular;
  src: url(../fonts/dm_sans/DMSans-Regular.ttf);
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../fonts/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/poppins/Poppins-SemiBold.ttf);
}

/*============================================ MID CSS ======================================================== */

/* ----------------------------------------------  SIGN UP ----------------------------------------------------------- */
.signup-row {
  display: flex;
  min-height: 100vh;
}

.CompositionImg {
  height: 100% !important;
}

.signup-col1 {
  text-align: left !important;
  width: 100%;
  flex: 0 0 512px;
  max-width: 512px;
}

.logo1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 44px;
  /* margin-left: 80px; */
  width: 512px;
}

.Victorlogo {
  height: 80px;
  width: 80px;
}

.Ncxlogo {
  margin-left: 8px;
  height: 48px;
  width: 146px;
}

.signup-col2 {
  flex: 1;
  padding: 0px 50px;
}

.login-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 51px 80px 50px 0px;
}

.logintext {
  font-weight: 700 !important;
  font-family: DMSans-Regular !important;
}

.logintext a {
  text-decoration: none !important;
}

.SignUp {
  font-weight: 700;
  text-align: center;
  font-family: DMSans-Bold !important;
  font-size: 32px;
  line-height: 40px;
  /* color: #23262F !important; */
}

.signup-openid {
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  margin: 32px 0px 20px;
  font-family: "Poppins-Medium";
}

.sign-up-main {
  max-width: 380px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.border-bottom-signup {
  border-bottom: 1px solid black;
  width: 100%;
  margin: 32px 0px;
}

.icon-button {
  margin-right: 13.53px;
}

.signup-google {
  width: 100%;
  min-width: 180px !important;
  height: 48px !important;
  background-color: #3772ff !important;
  border-radius: inherit !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  font-family: DMSans-Regular !important;
}

.signup-apple {
  width: 100%;
  min-width: 180px !important;
  height: 48px !important;
  background-color: #23262f !important;
  border-radius: inherit !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  font-family: DMSans-Regular !important;
}

.signup-continue-text {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  font-family: Poppins-Regular !important;
  margin: 0px 0px 32px;
  margin-top: 32px;
}

.signup-field {
  border-radius: inherit !important;
  height: 48px !important;
  border: 1px solid #b1b5c3 !important;
}

.signup-field input {
  padding: 10.5px 14px !important;
}

.input-field {
  width: 100% !important;
  margin-bottom: 32px;
  border-radius: none;
}

.input-mobile {
  display: flex;
}

.input-country-code {
  margin-right: 8px;
  position: relative;
}

.signup-agreements {
  display: flex;
  align-items: baseline;
}

.signup-agreements .sign-up-policy-text {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.signup-submit {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-bottom: 54px !important;
}

.signup-submit .signup-submit-button {
  height: 48px !important;
  border-radius: inherit !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-transform: inherit !important;
  font-family: "DMSans-Regular" !important;
  background-color: #3772ff;
  box-shadow: none;
}

/* ------------------------------------------------SignupVerifyOtp------------------------------------------------- */
.signup-verify-otp-main-content {
  margin-top: 256px;
}

.Signup-verify-otp-main {
  max-width: 380px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.signup-verify-otp-text {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  color: #23262f;
  font-family: DMSans-Regular !important;
}

.signup-verify-otp-text2 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* color: #777E91; */
  width: 100%;
  max-width: 380px;
  text-align: center;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-top: 48px !important;
  font-family: Poppins-Regular !important;
}

.radio-field {
  width: 100%;
  max-width: 380px;
  margin: auto;
  display: flex;
}

.bg-f8f8f9 {
  /* background-color: #f8f8f9 !important; */
}

.radio-field-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  display: block;
  font-family: Poppins-Regular !important;
}

.mb-0 {
  margin: 0px;
}

.divider {
  width: 100% !important;
  max-width: 380px !important;
  margin: auto !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.signup-verify-otp-button {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 32px;
  padding: 24px 0px;
}

/* -------------------------------------------Sign Up Security Code------------------------------------------------- */
.signup-security-code-text {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  font-family: DMSans-Bold !important;
}

.signup-mobile-code-text {
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: Poppins-Regular !important;
}

.signup-security-code-otp-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.signup-security-otp-input-field {
  width: 100%;
  max-width: 83px;
  margin: 12px !important;
  background-color: #f4f5f6;
  border-radius: inherit;
}

.signup-email-code-text {
  width: 100%;
  max-width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 23px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: Poppins-Regular !important;
}

/* -------------------------------------------- Signup Authenticator -------------------------------------------------  */

.download-authenticator {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 40px;
  margin-bottom: 10px;
  color: #000000;
  font-family: DMSans-Bold !important;
}

.download-authenticator-guide {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #777e90;
  font-family: Poppins-Regular !important;
}

.storebtn {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  display: flex;
}

.appstore-img {
  width: 120px;
  height: 40px !important;
  margin-top: 20px;
  margin-right: 20px;
}

.androidstore-img {
  height: 40px !important;
  margin-top: 20px;
  margin-right: 20px;
}

.add-key-authenticator {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  margin-top: 68px;
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 40px;
  font-family: DMSans-Bold !important;
}

.google-qr-text {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #777e90;
  margin-top: 10px;
  font-family: Poppins-Regular !important;
}

.SignupQr {
  width: 100%;
  max-width: 1179px;
  margin: auto;
}

.qr {
  margin-top: 10px;
}

.qr-text {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  font-family: Poppins-Regular !important;
}

.six-digit-code-text {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 40px;
  margin-top: 38px;
  font-family: DMSans-Bold !important;
}

.six-digit-code {
  width: 100%;
  max-width: 1179px;
  display: flex;
  margin: auto;
  margin-top: 12px;
}

.six-digit-code-field {
  width: 100%;
  max-width: 65px;
  background-color: #f4f5f6;
  border-radius: inherit;
  margin-right: 16px !important;
}

.google-authenticator-submit {
  width: 100%;
  max-width: 1179px;
  margin: auto;
  margin-top: 29px;
}

.google-authenticator-submit-btn {
  width: 100%;
  max-width: 202px;
  margin: auto;
  border-radius: inherit !important;
  height: 48px;
  text-transform: capitalize !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-family: DMSans-Regular !important;
}

/* ------------------------------------------------Sign Up Forgot Password-------------------------------------- */
.sign-up-free-text {
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  font-family: "DMSans-regular" !important;
}

.login-text a {
  text-decoration: none !important;
}

.forgot-password-heading {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #23262f;
  margin-top: 128px;
  font-family: DMSans-Bold !important;
}

.forgot-password-condition {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #777e91;
  margin-top: 32px;
  font-family: Poppins-Regular !important;
}

.forgot-password-divider {
  width: 100%;
  max-width: 380px;
  margin: auto !important;
  margin-top: 32px !important;
  background: #b1b5c3;
}

.forgot-password-input-field {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 32px;
}

.forgot-password-field {
  border: 1px solid #b1b5c3 !important;
  border-radius: 0px !important;
}

.forgot-password-input-label {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  margin-bottom: 12px !important;
  font-family: Poppins-Bold !important;
}

.forgot-password-continue {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 32px !important;
}

.forgot-password-continue-botton {
  border-radius: 2px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  height: 48px !important;
  font-family: DMSans-Regular !important;
}

.forgot-password-exit-text {
  width: 100%;
  max-width: 26px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 24px;
}

.forgot-password-exit {
  text-decoration: none !important;
  font-family: DMSans-Regular !important;
}

/*------------------------------------------ Security Verification -------------------------------------------- */
.security-verification-col2 {
  flex: 1;
}

.security-verification-text {
  width: 100%;
  max-width: 400px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #23262f;
  font-family: DMSans-Bold !important;
}

.security-verification-mail {
  width: 100%;
  max-width: 400px;
  margin: auto;
  text-align: center !important;
  margin-top: 32px !important;
}

.security-verification-mail-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #353945;
  font-family: Poppins-Regular !important;
}

.security-verification-mail-text2 {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  margin-top: 4px !important;
  display: flex;
  font-family: Poppins-Regular !important;
}

.security-verification-otp-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 24px;
  max-width: 400px;
}

.security-verification-otp-input-field {
  width: 100%;
  max-width: 88px;
  margin: 12px !important;
  /* background-color: #F4F5F6; */
  border-radius: inherit;
}

.security-verification-otp-input div:first-child > div {
  margin-left: 0 !important;
}

.security-verification-btn {
  width: 100%;
  max-width: 400px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.security-verification-resend-code-btn {
  border-radius: 0px !important;
  text-transform: capitalize !important;
  border-color: #23262f !important;
  font-family: DMSans-Regular !important;
}

.security-verification-continue-btn {
  border-radius: 0px !important;
  text-transform: capitalize !important;
  color: #fcfcfd;
  font-family: DMSans-Regular !important;
}

/* -------------------------------------------New Password ---------------------------------------------------- */
.new-password-text {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  margin-top: 160px;
}

.new-password-sign-in-form-label {
  font-family: Poppins-Bold !important;
  font-size: 12px !important;
  margin-bottom: 12px !important;
  color: #b1b5c3 !important;
}

.new-password-input-field {
  width: 100%;
  max-width: 380px;
  margin-top: 32px !important;
  margin: auto;
}

.new-password-submit {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 32px !important;
}

/* --------------------------------------------------SIGN IN TO NCX------------------------------------------------- */
.sign-in-heading {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  font-family: DMSans-Bold;
  text-align: center;
  color: #141416;
  font-family: DMSans-Bold !important;
  margin-top: 128px !important;
}

.sign-in-text {
  width: 100%;
  max-width: 380px;
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #b1b5c3;
  margin-top: 32px;
  font-family: Poppins-Regular;
  text-align: center;
  font-family: Poppins-Regular !important;
}

.sign-in-url-address {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 12px;
  background-color: #f4f5f6;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-address1 {
  color: #28b594;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins-Regular !important;
}

.sign-in-address2 {
  color: #23262f;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins-Regular !important;
}

.sign-in-divider {
  width: 100%;
  max-width: 380px;
  margin: auto !important;
  margin-top: 32px !important;
  height: 1px;
}

.sign-in-form {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 32px !important;
}

.sign-in-form-label {
  font-family: Poppins-Bold !important;
  font-size: 12px !important;
  margin-bottom: 12px !important;
}

.sign-in-form-link {
  width: 100%;
  max-width: 380px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.sign-in-scan-to-login {
  color: #141416 !important;
  font-family: Poppins-SemiBold !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  text-decoration: none !important;
}

.signin-login-button {
  height: 48px !important;
  text-transform: capitalize !important;
  border-radius: inherit !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-family: DMSans-Regular !important;
  margin-top: 32px !important;
}

.signin-tabpanel > div {
  padding: 0 !important;
  margin-top: 32px !important;
}

.sign-in-form [role="tabpanel"] > div {
  padding: 0 !important;
  margin-top: 32px !important;
}

.signin-tabs [role="tablist"] {
  justify-content: center;
}

.signin-tabs [role="tablist"] button {
  padding: 0 !important;
  height: 20px;
  border: 0 !important;
}

.signin-tabs [role="tablist"] button[aria-selected="true"] {
  background: #353945;
  color: #fff;
  border: 0 !important;
  font-family: DMSans-Regular !important;
}

.signin-tabs .MuiTabs-indicator {
  display: none;
}

/* ==============================================EXCHANGE PAGE=================================================*/
.exchange-table {
  width: 100%;
  max-width: 256px;
  flex: 0 0 256px;
  margin-right: 4px;
}

.exchange-middle-content {
  flex: 1;
}

.exchange-price-table {
  width: 100%;
  max-width: 261px;
  flex: 0 0 261px;
  margin-left: 4px;
}

.card-content {
  padding-bottom: 0px !important;
}

/* ---------------------Exchange Header-------------------- */

.exchange-header-card-content {
  padding-bottom: 0px !important;
}

.exchange-header-main {
  width: 100% !important;
  max-width: 1432px !important;
  margin: auto;
  margin-top: 4px !important;
  background-color: transparent !important;
  display: flex !important;
  background-color: #fcfcfd !important;
  box-shadow: none !important;
  padding: 0 !important;
}

/* .exchange-header-main > div {
    padding: 0px !important;
} */

.exchange-header-content1 {
  display: flex !important;
  align-items: center !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.exchange-header-content1-coin1 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #141416 !important;
  font-family: Poppins-Bold !important;
}

.exchange-header-content1-coin2 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #777e91;
  font-family: Poppins-Bold !important;
}

.exchange-header-content1-price1 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #141416 !important;
  font-family: Poppins-Bold !important;
}

.exchange-header-content1-price2 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  color: #777e91;
  font-family: Poppins-Bold !important;
  display: flex;
  align-items: center;
}

.exchange-header-downarrow-button {
  width: 100% !important;
  max-width: 32px !important;
  margin-left: 11px !important;
  padding: 0px !important;
  border: 1px solid #e6e8ec !important;
  border-radius: 0px !important;
  height: 32px;
}

.exchange-header-content2 {
  margin-left: auto !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.exchange-header-change {
  color: #000000 !important;
  width: 118px !important;
  height: 48px !important;
  width: 100% !important;
  max-width: 160px !important;
  border-right: 2px solid #e6e8ec;
}

.exchange-header-content2-icons {
  width: 100%;
  max-width: 16px !important;
  height: 16px !important;
}

.exchange-header-content2-24h-text {
  color: #777e90;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-family: Poppins-Regular !important;
}

.exchange-header-content2-text {
  color: #00c076;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins-Medium !important;
}

.exchange-header-content2-text2 {
  color: #141416;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-family: Poppins-Medium !important;
}

/* ---------------------Exchange Table-------------------- */

.exchange-main-table {
  width: 100%;
  max-width: 256px;
}

.exchange-table-icons {
  width: 100% !important;
  max-width: 256px !important;
  margin-top: 16px;
  margin-bottom: 12px;
  display: flex !important;
}

.exchange-table-icons-img {
  max-width: fit-content;
}

.dropdown {
  width: 100%;
  min-width: 32px !important;
  min-height: 32px !important;
}

.exchange-table-middle-content {
  width: 100% !important;
  max-width: 224px !important;
  display: flex;
  margin: 12px 16px !important;
  /* border: 1px solid black; */
}

.exchange-table-middle-content-text {
  color: #28b594 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: Poppins-Medium !important;
  margin-right: 8px;
  padding: 12px 0px 12px 16px !important;
}

.exchange-table-middle-content-text2 {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: Poppins-Medium !important;
  margin-left: 8px;
  padding: 12px 0px 12px 0px !important;
}

.pricing-cell-red {
  color: #f84343 !important;
  height: 28px !important;
  font-family: Poppins-Medium !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.pricing-cell-green {
  color: #28b594 !important;
  height: 28px !important;
  font-family: Poppins-SemiBold !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.pricing-cell-header {
  color: #777e90 !important;
  line-height: 1rem !important;
  padding: 0px !important;
  font-size: 12px !important;
  font-family: Poppins-SemiBold !important;
  line-height: 20px !important;
  font-style: normal !important;
}

.pricing-cell {
  height: 28px !important;
  font-family: Poppins-Medium !important;
  font-style: normal !important;
  font-size: 12px !important;
  font-family: Poppins-Medium !important;
  line-height: 20px !important;
}

.red-green-table table tr th {
  padding-bottom: 12px !important;
}

/* .red-green-table tbody tr:nth-child(1) {
    background-image: linear-gradient(to right, rgba(248,67,67,0.15), rgba(248,67,67,0.15)) !important;
    background-repeat: no-repeat;
    background-size: 42%;
    background-position: right;
}
.red-green-table tbody tr:nth-child(3) {
    background-image: linear-gradient(to right, rgba(248,67,67,0.15), rgba(248,67,67,0.15)) !important;
    background-repeat: no-repeat;
    background-size: 46%;
    background-position: right;
}
.red-green-table tbody tr:nth-child(5) {
    background-image: linear-gradient(to right, rgba(248,67,67,0.15), rgba(248,67,67,0.15)) !important;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right;
}
.red-green-table tbody tr:nth-child(6) {
    background-image: linear-gradient(to right, rgba(248,67,67,0.15), rgba(248,67,67,0.15)) !important;
    background-repeat: no-repeat;
    background-size: 33%;
    background-position: right;
}
.red-green-table tbody tr:nth-child(6) {
    background-image: linear-gradient(to right, rgba(248,67,67,0.15), rgba(248,67,67,0.15)) !important;
    background-repeat: no-repeat;
    background-size: 33%;
    background-position: right;
}
.red-green-table tbody tr:nth-child(7) {
    background-image: linear-gradient(to right, rgba(248,67,67,0.15), rgba(248,67,67,0.15)) !important;
    background-repeat: no-repeat;
    background-size: 33%;
    background-position: right;
} */

.red-green-table table tr th:nth-child(1) {
  padding-left: 16px !important;
  width: 33.33% !important;
}

.red-green-table table tr th:nth-child(2) {
  width: 33% !important;
  text-align: right !important;
  padding: 0 6% !important;
}

.red-green-table table tr th:nth-child(3) {
  width: 33% !important;
  padding-right: 16px !important;
  text-align: right !important;
}

.red-green-table table tr td:nth-child(1) {
  padding-left: 16px !important;
  width: 33.33% !important;
  font-family: "Poppins-semibold" !important;
}

.red-green-table table tr td:nth-child(2) {
  width: 33% !important;
  text-align: right !important;
  padding: 6px 6% !important;
  color: #353945 !important;
}

.red-green-table table tr td:nth-child(3) {
  width: 33% !important;
  padding-right: 16px !important;
  text-align: right !important;
  color: #353945 !important;
}

.table2 {
  width: 100% !important;
  max-width: 261px !important;
}

.table2 [role="tabpanel"] div {
  padding: 0px !important;
}

.table2 tr th {
  padding: 4px 0px !important;
  padding-bottom: 12px !important;
}

.table2 tr th:nth-child(1) {
  width: 40% !important;
  padding-left: 13px !important;
}

.table2 tr th:nth-child(2) {
  width: 30% !important;
}

.table2 tr th:nth-child(3) {
  width: 20% !important;
}

.table2 tr td:nth-child(1) {
  width: 40% !important;
  padding-left: 10px !important;
}

.table2 tr td:nth-child(2) {
  width: 30% !important;
}

.table2 tr td:nth-child(3) {
  width: 20% !important;
  padding-right: 10px !important;
  text-align: right !important;
}

.table2-tabs {
  min-height: 28px !important;
  /* margin-top: 20px !important; */
}

.table2-tabs button {
  min-height: auto !important;
  text-transform: capitalize !important;
  min-width: auto !important;
}

.table2-tabs [role="tablist"] button[aria-selected="true"] {
  background: #e6e8ec !important;
  color: #23262f !important;
  border: 0 !important;
}

.table2-tabs #simple-tab-0 {
  height: 28px;
  width: 42px;
  padding: 6px 12px;
  margin-left: 10px;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.table2-tabs #simple-tab-1 {
  padding: 6px 12px;
  height: 28px;
  width: 63px;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.table2-tabs #simple-tab-2 {
  padding: 6px 12px;
  height: 28px;
  width: 79px;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.table2-tabs .MuiTabs-indicator {
  display: none !important;
}

.table3 {
  width: 100% !important;
  max-width: 261px !important;
  margin-top: 4px !important;
}

.table3 [role="tabpanel"] div {
  padding: 0px !important;
}

.table3 tr th {
  padding: 4px 0px !important;
  padding-bottom: 10px !important;
}

.table3 tr th:nth-child(1) {
  width: 40% !important;
  padding-left: 16px !important;
  padding-right: 26px !important;
}

.table3 tr th:nth-child(2) {
  width: 30% !important;
}

.table3 tr th:nth-child(3) {
  width: 20% !important;
  padding-right: 16px !important;
}

.table3 tr td:nth-child(1) {
  width: 40% !important;
  padding-left: 16px !important;
}

.table3 tr td:nth-child(2) {
  width: 30% !important;
}

.table3 tr td:nth-child(3) {
  width: 20% !important;
  padding-right: 16px !important;
  text-align: right !important;
}

.table3-tabs button {
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  margin-top: 16px !important;
}

.table3-tabs #simple-tab-0 {
  padding: 6px 12px !important;
  height: 28px;
  width: 127px;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.table3-tabs #simple-tab-1 {
  padding: 6px 12px !important;
  width: 98px;
  height: 28px;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.table3-tabs [role="tablist"] button[aria-selected="true"] {
  background: #e6e8ec !important;
  color: #23262f !important;
  border: 0 !important;
}

.table3-tabs .MuiTabs-indicator {
  display: none !important;
}

.exchange-form-buy-slider span {
  box-shadow: none !important;
}

/* .exchange-control .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
    border-color: transparent !important;
    border: 0px !important;
} */

.card-content .exchange-search-box {
  margin: 12px 10px 12px 11px !important;
}

.MiddleBottomTable {
  width: 100% !important;
  max-width: 912px !important;
  margin: auto !important;
  margin-top: 4px !important;
}

.MiddleBottomTable [role="tabpanel"] div {
  padding: 0px !important;
}

.MiddleBottomTable table {
  max-width: 100% !important;
}

.MiddleBottomTable .MuiTabs-scroller {
  margin-bottom: 16px !important;
}

.MiddleBottomTable table tr th {
  padding: 4px 0px 12px 0px !important;
}

.MiddleBottomTable tr th:nth-child(1) {
  width: 20% !important;
  text-align: left !important;
}

.tabMiddleBottomTablele4 tr th:nth-child(2) {
  width: 20% !important;
}

.MiddleBottomTable tr th:nth-child(3) {
  width: 20% !important;
}

.MiddleBottomTable tr th:nth-child(4) {
  width: 20% !important;
  text-align: right !important;
}

.MiddleBottomTable tr td:nth-child(1) {
  width: 20% !important;
  text-align: left !important;
}

.MiddleBottomTable tr td:nth-child(2) {
  width: 25% !important;
}

.MiddleBottomTable tr td:nth-child(3) {
  width: 25% !important;
}

.MiddleBottomTable tr td:nth-child(4) {
  width: 20% !important;
  text-align: right !important;
}

.MiddleBottomTable-tabs button {
  min-height: auto !important;
  min-width: auto !important;
  text-transform: capitalize !important;
}

.MiddleBottomTable-tabs #simple-tab-0 {
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  width: 135px !important;
  height: 28px !important;
  padding: 6px 12px !important;
}

.MiddleBottomTable-tabs #simple-tab-1 {
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  width: 98px !important;
  height: 28px !important;
  padding: 6px 12px !important;
}

.MiddleBottomTable-tabs #simple-tab-2 {
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  width: 116px !important;
  height: 28px !important;
  padding: 6px 12px !important;
}

.MiddleBottomTable-tabs #simple-tab-3 {
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
  width: 92px !important;
  height: 28px !important;
  padding: 6px 12px !important;
}

.MiddleBottomTable-tabs [role="tablist"] button[aria-selected="true"] {
  background: #e6e8ec !important;
  color: #23262f !important;
  border: 0 !important;
}

.MiddleBottomTable-tabs .MuiTabs-indicator {
  display: none !important;
}

/* -----------------------Exhchange Graph---------------------- */
.exchange-graph-card-content {
  padding-bottom: 0px !important;
}

.exchange-graph-time {
  text-align: center !important;
  align-items: center !important;
}

.exchange-graph-timeline {
  display: flex !important;
  width: 100%;
  max-width: 375px;
  margin: 18px 16px 18px 16px !important;
}

.exchange-graph-timeline-content2 {
  width: 100%;
  max-width: 211px;
  margin-left: 294px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exchange-graph-timeline-text {
  width: 100%;
  max-width: 39px !important;
  height: 28px;
  margin-right: 16px !important;
}

.exchange-graph-timeline-btn {
  text-transform: capitalize !important;
  text-decoration: none !important;
  color: #777e90 !important;
  font-family: "DMSans-Bold" !important;
  padding: 0px !important;
}

.exchange-graph-timeline-tradingView-btn {
  text-transform: capitalize !important;
  font-style: normal !important;
  font-family: DMSans-Bold !important;
  font-size: 11px !important;
  line-height: 16px !important;
  background-color: #e6e8ec !important;
  color: #23262f !important;
  border-radius: 0px !important;
  padding: 6px 12px !important;
}

.exchange-graph-timeline-depth-btn {
  text-transform: capitalize !important;
  color: #777e91 !important;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 9px 12px !important;
  border-radius: 0px !important;
}

.exchange-graph {
  width: 100%;
  max-width: 912px;
  height: 496px;
}

/* ----------------------------Exchange Buy Sell Form--------------------- */

.exchange-form {
  width: 100%;
  max-width: 912px !important;
  height: 396px;
  margin: auto;
  padding-top: 16px !important;
  /* border: 1px solid blueviolet; */
}

.exchange-form-buy {
  width: 100% !important;
  max-width: 424px !important;
  height: 312px;
  margin-right: 32px !important;
  /* border: 1px solid red; */
}

.exchange-form-buy-headline {
  width: 100%;
  max-width: 424px;
  max-height: 32px !important;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  text-align: center !important;
}

.exchange-form-buy-buy-btc {
  font-family: Poppins-Bold !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 32px !important;
}

.exchange-form-buy-head-price {
  font-family: Poppins-Bold !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 20px !important;
  display: flex;
}

.exchange-form-buy-price-input-field {
  border-radius: 0px !important;
  height: 48px !important;
  border: 1px solid #e6e8ec !important;
}

.exchange-form-buy-btc-btn {
  width: 100%;
  max-width: 424px !important;
  height: 48px !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
  font-style: normal !important;
  font-family: DMSans-Bold !important;
  font-size: 16px !important;
  line-height: 16px !important;
  margin-top: 12px !important;
}

.exchange-form [role="tabpanel"] > div {
  padding: 0px 0px 0px 16px !important;
}

.left-side-table-dropdown .left-side-select {
  background-color: #f1f2f4;
  position: relative;
}

.left-side-table-dropdown .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px;
}

.left-side-table-dropdown .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  display: none;
}

.left-side-select-down-img {
  position: absolute;
  height: 27px;
  width: 30px;
  right: 0px;
  top: 1px;
}

.form-tabs button {
  min-height: auto !important;
  min-height: auto !important;
  text-transform: capitalize !important;
}

.form-tabs #simple-tab-0 {
  width: 59px !important;
  height: 28px !important;
  padding: 6px 12px !important;
  margin-left: 16px !important;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.form-tabs #simple-tab-1 {
  width: 102px !important;
  height: 28px !important;
  padding: 6px 12px !important;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.form-tabs #simple-tab-2 {
  width: 72px !important;
  height: 28px !important;
  padding: 6px 12px !important;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: capitalize !important;
}

.form-tabs [role="tablist"] button[aria-selected="true"] {
  background: #e6e8ec !important;
  color: #23262f !important;
  border: 0 !important;
}

.form-tabs .MuiTabs-indicator {
  display: none !important;
}

/* ===========================================Sell Crypto 5 And Buy Crypto 5================================== */
.sell-crypto5-table-container {
}

.sell-crypto5-table {
  width: 100% !important;
  min-width: 656px;
}

.sell-crypto5-table-header th {
  color: #777e91;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
  /* border-bottom: 0px !important; */
  padding: 18px 0px;
}

.sell-crypto5-table-rows td {
  height: 65px !important;
  /* border-bottom: 0px !important; */
  padding: 0px;
}

.sell-crypto5-table-container {
  background-color: inherit !important;
}

.sell-crypto5-analysis-panel {
  font-family: "IBMPlexSans-Regular";
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  border: 1px solid #fcfcfd;
  box-sizing: border-box;
  padding: 24px 0px;
  justify-content: space-between;
}

.sell-crypto5-box {
  display: flex;
}

.sell-crypto5-box:last-child {
  margin-right: 0px;
}

.sell-crypto5-datatable-logo img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.coin-title {
  margin: 0px;
  font-family: "IBM Plex Sans ";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #23262f;
}

.coin-sub-title {
  color: #777e91;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0px;
}

/* .market-datatable-data {} */

.table-sort-icon {
  /* display: flex; */
  flex-direction: column;
  display: inline-flex;
}

.sell-crypto5-table-header th {
  color: #777e91;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
}

.sell-crypto5-table-header th button {
  color: #777e91;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
}

.sell-crypto5-table-header {
  height: 102px;
}

.icon-fonts {
  font-size: 12px;
  margin: 0;
}

.sell-crypto5-table-rows {
  /* font-size: 12px; */
  margin: 0;
  height: 73px;
}

.sell-crypto5-table-rows td {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.sell-crypto5-table-rows p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 0px 0px 10px;
}

/* .sell-crypto5-table-rows td,  th 
{border-bottom: 1px solid red;} */

.market-table-name-column {
  display: inline-block;
}

.sell-crypto5-table-name-column p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.sell-crypto5-table-rows .coin-svg {
  height: 25px;
  width: 25px;
}

/* for row hover desktop */

.sell-crypto5-table-rows:hover {
  /* background-color: #F4F5F6 !important */
}

.sell-crypto5-table-rows:hover button {
  display: inline-block;
}

.sell-crypto5-table-rows:hover .star span {
  color: #777e91;
}

.sell-crypto5-table-rows .graph {
  min-width: 96px;
}

/* ------------------------------------Withdraw Crypto ------------------------------------------- */

body {
  background-color: #f4f5f6;
}

.wcrypto-main {
  max-width: 1290px;
  width: 100%;
  background-color: #fcfcfd;
  margin: 20px auto;
}

.wcypto-top-box {
  padding: 40px 33px 32px 32px;
}

.wcrypto-title {
  font-size: 32px;
  line-height: 40px;
  color: #23262f;
  font-family: "DMSans-Bold";
}

.wc-top-btns-group {
  display: flex;
}

.wcypto-title-main-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wc-top-btns-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wc-top-btns-group .btn-wcypto {
  height: 48px;
  width: 100%;
  min-width: 150px;
  border: 1.5px solid #b1b5c3;
  border-radius: 0px;
  color: #141416;
  font-size: 16px;
  line-height: 16px;
  font-family: DMSans-bold;
  text-transform: capitalize;
  margin-right: 10px;
}

.wc-top-btns-group .btn-wcypto:last-child {
  margin-right: 0px;
}

.btn-wcypto:active,
.btn-wcypto:hover {
  background-color: #3772ff !important;
  color: #fff;
}

.wcypto-bal-box {
  max-width: 884px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.wcypto-bal .wcypto-bal-title {
  color: #353945;
  font-size: 14px;
  line-height: 24px;
  font-family: poppins-medium;
  margin-bottom: 4px;
}

.wcypto-bal .wcypto-bal-price {
  font-size: 24px;
  line-height: 32px;
  color: #23262f;
  font-family: poppins-semibold;
}

.wc-fiat-bal-box {
  max-width: 1290px;
  width: 100%;
  margin: auto;
  /* padding: 32px; */
  padding: 15px 0px;

  background-color: #fcfcfd;
}

.wc-fiat-title-box .wc-fiat-title {
  font-size: 32px;
  line-height: 40px;
  color: #23262f;
  font-family: "DMSans-Bold";
  /* margin-bottom:20px !important; */
  padding-bottom: 20px !important;
}

.withdraw-crypto-table {
  width: 100% !important;
  min-width: 1226px;
}

.withdraw-crypto-table-header th {
  color: #353945 !important;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
  border-bottom: 0px !important;
  padding: 0;
}

.withdraw-crypto-table-rows td {
  height: 65px !important;
  border-bottom: 0px !important;
  padding: 20px 0px;
}

.withdraw-crypto-table-container {
  background-color: inherit !important;
}

.withdraw-crypto-analysis-panel {
  font-family: "IBMPlexSans-Regular";
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  border: 1px solid #fcfcfd;
  box-sizing: border-box;
  padding: 24px 0px;
  justify-content: space-between;
}

.withdraw-crypto-box {
  display: flex;
}

.withdraw-crypto-box:last-child {
  margin-right: 0px;
}

.withdraw-crypto-datatable-logo img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

.coin-title {
  margin: 0px;
  font-family: "IBM Plex Sans ";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #23262f;
}

.coin-sub-title {
  color: #777e91;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0px;
}

.table-sort-icon {
  flex-direction: column;
  display: inline-flex;
}

.icon-fonts {
  font-size: 12px;
  margin: 0;
}

.withdraw-crypto-table-rows {
  margin: 0;
  height: 73px;
  border-bottom: 1px solid #f5f5f5;
}

.withdraw-crypto-table-rows td {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.withdraw-crypto-table-rows p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 0px 0px 10px;
}

.withdraw-crypto-table-name-column {
  display: inline-block;
}

.withdraw-crypto-table-name-column p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.withdraw-crypto-table-rows .coin-svg {
  height: 25px;
  width: 25px;
}

.withdraw-crypto-th-currency {
  width: 368px;
  padding-right: 232px !important;
}

.withdraw-crypto-th-balance {
  width: 400px;
  padding-right: 100px !important;
  text-align: right !important;
}

.withdraw-crypto-th-available {
  width: 363px;
  padding-right: 232px !important;
  text-align: right !important;
}

.withdraw-crypto-th-inorder {
  text-align: right !important;
}

.withdraw-crypto-td-balance {
  text-align: right !important;
  padding-right: 100px;
}

.withdraw-crypto-td-token-price {
  text-align: right !important;
  padding-right: 232px;
}

.withdraw-crypto-td-available {
  text-align: right !important;
  padding-right: 232px !important;
}

.withdraw-crypto-td-inorder {
  text-align: right !important;
}

.withdraw-crypto-funds-text {
  width: 100%;
  max-width: 1290px !important;
  margin: auto;
  margin-top: 20px !important;
  margin-bottom: 8px !important;
  padding-left: 32px !important;
  color: #777e90 !important;
}

.wc-funds-table {
  width: 100%;
  max-width: 1290px !important;
  margin: auto;
  padding: 32px;
  background-color: #fcfcfd;
  margin-bottom: 63px !important;
}

.hover-show-btn-box {
  display: none;
}

.withdraw-crypto-table-rows:hover .hover-show-btn-box {
  display: block;
}

.wc-fund-serchbox-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wc-brows-text {
  font-size: 12px;
  line-height: 20px;
  color: #777e91;
}

.wc-right-arrow {
  height: 10px;
  width: 10px;
  object-fit: contain;
  margin-left: 13.5px;
}

.wc-search-box input {
  width: 100%;
  min-width: 256px;
  max-width: 211px;
  height: 40px;
}

.wc-hover-btn-group .wc-hover-btn-text {
  font-size: 12px;
  line-height: 16px;
  color: #23262f;
}

.wc-hover-btn-group .wc-hover-btn-text:hover {
  color: #fff;
}

.wc-hover-btn-group {
  margin-top: 13px;
}

.wc-hover-btn-group .wc-hover-deposit-btn {
  width: 100%;
  min-width: 68px;
  height: 32px;
}

.wc-hover-btn-group .wc-hover-withdrow-btn {
  width: 100%;
  min-width: 80px;
  height: 32px;
}

.wc-hover-btn-group .wc-hover-convert-btn {
  width: 100%;
  min-width: 69px;
  height: 32px;
}

.wc-hover-btn-group .wc-hover-buy-btn {
  width: 100%;
  min-width: 46px;
  height: 32px;
}

.wc-hover-btn-group .wc-hover-sell-btn {
  width: 100%;
  min-width: 46px;
  height: 32px;
}

.wc-confirm-modal-btn-box .wc-confirm-modal-btn {
  max-width: 100% !important;
  margin-top: 32px !important;
}

.wc-modal-main {
  width: 100% !important;
  max-width: 448px !important;
  padding: 32px !important;
}

.wc-modal-head {
  width: 100% !important;
  max-width: 384px !important;
  width: auto !important;
  justify-content: left !important;
}

.wc-modal-withdraw-eth {
  margin-left: 16px;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 40px !important;
}

.wc-modal-closeimg {
  margin-left: auto !important;
}

.wc-modal-address {
  margin-top: 32px !important;
  margin-bottom: 12px !important;
}

.wc-modal-warning-text {
  margin-top: 12px !important;
  font-family: Poppins-Medium !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 16px !important;
}

.wc-modal-checkbox-text {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.wcrypto-title-bal {
  display: none;
}

.three-dots-img {
  display: none;
}

.withdraw-crypto-table-header th:nth-child(3) {
  display: none;
}

.withdraw-crypto-table-rows td:nth-child(3) {
  display: none;
}

.wc-modal-save-add-text {
  font-family: Poppins-SemiBold !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.wc-modal-balance {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 20px 24px;
}

.wc-modal-balance-text {
  width: 67px;
  word-wrap: break-word;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.wc-modal-eth-balance {
  font-family: Poppins-SemiBold !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.wc-modal-eth-balance-doller {
  font-family: Poppins-Regular !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.wc-modal-amount-withdraw {
  font-family: Poppins-Bold !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  text-transform: uppercase !important;
  margin-top: 32px !important;
}

.wc-modal-max-amount-btn {
  border: 2px solid #b1b5c3 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
  font-family: DMSans-Bold !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.wc-modal-withdrawal-limit {
  margin-top: 12px !important;
  font-family: Poppins-Medium !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.wc-modal-message {
  width: 100% !important;
  max-width: 448px !important;
  padding: 32px !important;
}

.by10-modal-close {
  margin-left: auto !important;
}

.wc-modal-message-main-status {
  border: 1.5px solid #b1b5c3;
}

.wc-modal-message-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 24px 24px 16px 24px !important;
  margin-bottom: 0px !important;
}

.wc-modal-message-divider {
  width: 100% !important;
  max-width: 336px !important;
  margin: auto !important;
}

.wc-modal-message-address {
  width: 100% !important;
  max-width: 336px !important;
  margin: 16px 24px 24px 24px !important;
}

.wc-modal-message-address-text {
  font-family: Poppins-Regular !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.wc-modal-message-wallet-address {
  font-family: Poppins-SemiBold !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.wc-modal-btn-arrow {
  margin-right: 12px !important;
}

/* --------------------------------------------Staking Page------------------------------------------ */

.staking-head {
  width: 100%;
  max-width: 1423px;
  margin: auto !important;
  padding: 30px 0px 20px 0px !important;
}

.staking-head-inner {
  width: 100%;
  max-width: 1423px;
  margin: auto;
  margin: 32px 0px !important;
  padding: 16px 0px !important;
  display: flex !important;
  justify-content: space-between !important;
}

.staking-head-balance {
  display: flex;
  width: 100%;
  max-width: 476px;
  justify-content: space-between;
}

.staking-head-balance-text {
  font-family: DMSans-Bold !important;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
}

.staking-head-balance-usd {
  font-family: DMSans-Bold !important;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 28px;
}

.staking-head-rewards {
  display: flex;
  width: 100%;
  max-width: 460px;
  justify-content: space-between;
}

.staking-assets-main {
  width: 100%;
  max-width: 1423px;
  margin: auto;
  padding-bottom: 30px !important;
}

.staking-assets {
  display: flex;
  align-items: center;
  padding: 16px 0px 45px 0px !important;
  margin-bottom: 20px;
}

.staking-assets-text {
  width: 100%;
  max-width: 1101px;
  text-align: left;
  font-family: DMSans-Bold !important;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.staking-transaction-main {
  margin-top: 20px !important;
}

.staking-transaction {
  width: 100%;
  max-width: 1423px;
}

.staking-transaction-text {
  width: 100%;
  max-width: 1206px;
  padding: 8px 332px 10px 0px;
  font-family: DMSans-Bold !important;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.staking-transaction-main {
  width: 100%;
  max-width: 1423px;
  margin: auto;
}

.staking-table-header {
  padding: 0px !important;
}

.duration-btn-group {
  display: flex;
}

.btn-duration {
  min-width: auto !important;
}

.staking-table-rows td {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 0px !important;
  position: relative;
}

.staking-table-rows p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 0px 0px 0px;
}

.market-table-name-column {
  display: inline-block;
}

.market-table-name-column p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.staking-table-rows .coin-svg {
  height: 25px;
  width: 25px;
}

/* for row hover desktop */

.staking-table-rows button {
  /* display: none; */
}

.staking-table-rows:hover {
  background-color: #f4f5f6 !important;
}

.staking-table-rows:hover button {
  display: inline-block;
}

.staking-table-rows:hover .graph {
  display: none;
}

.staking-table-rows:hover .staking-hover {
  display: flex;
}

.staking-table-rows:hover .para {
  display: none;
}

.staking-table-rows:hover .star span {
  color: #777e91;
}

.staking-table-rows .graph {
  min-width: 96px;
}

.staking-table-rows td,
th {
  border-bottom: 1px solid #f5f5f5;
}

.staking-table-rows td .btn-duration {
  border: 1px solid #eaecef;
  border-radius: 4px;
  /* padding: 8px 12px; */
  max-width: 38px;
  max-height: 28px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.duration-btn-group .btn-duration:nth-child(4) {
  border: 1px solid #3772ff !important;
  max-width: 38px;
  max-height: 28px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  padding: 6px 7px 7px 7px !important;
}

.staking-hover {
  display: none;
  position: absolute;
  right: 13px;
  top: 12px;
}

.staking-bottom-text {
  width: 100%;
  max-width: 1423px;
  margin: auto;
  padding: 20px 0px 79px 0px;
}

.staking-bottom-text span {
  color: #3772ff;
}

.staking-term-service {
  width: 100%;
  max-width: 1290px;
  margin: 30px 0px 15px 0px;
  font-family: "DMSans-Bold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.staking-learn-more-about {
  width: 100%;
  max-width: 1290px;
  font-family: "DMSans-Bold" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.staking-table-header th {
  color: #777e91;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
  padding: 0px !important;
  margin-bottom: 20px !important;
}

.staking-transaction-header th {
  color: #777e91;
  text-transform: uppercase;
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  vertical-align: middle;
}

.staking-transaction-header th:nth-child(6) {
  text-align: right !important;
}

.staking-transaction-rows td:nth-child(6) {
  text-align: right !important;
}

.staking-table-rows {
  /* font-size: 12px; */
  margin: 0;
  height: 85px;
}

.staking-transaction-rows {
  margin: 0;
  height: 56px;
}

.staking-transaction-rows td {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.staking-transaction-rows p {
  font-family: "IBM Plex Sans Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 0px 0px 0px;
}

.staking-modal-main {
  width: 100%;
  max-width: 650px;
  padding: 32px;
}

.staking-modal-head {
}

.staking-modal-head-text {
  font-family: DMSans-Bold !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.staking-modal-search-box {
  width: 100%;
}

.staking-modal-search-box #fullWidth {
  width: 100% !important;
  min-width: auto;
  max-width: none;
  margin-top: 12px !important;
}

.staking-modal-card-text {
  display: inline-block !important;
}

.staking-modal-coin-short {
  margin: 0px !important;
}

.staking-modal-table-container {
  display: flex;
  justify-content: space-between !important;
}

.staking-modal-table-container .staking-card-data p {
  margin: 0px 0px 8px 0px;
}

.staking-modal-table-container .staking-card-data p:first-child {
  font-family: IBM Plex Sans Bold;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.staking-mobile-table-container .staking-card-data p:last-child {
  font-family: "IBM Plex Sans medium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.staking-modal-table-container {
  width: 100%;
  max-width: 586px;
  margin: auto !important;
  padding-top: 20px;
}

.de-amount-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.de-amount-text {
  color: #000000;
  font-size: 12px;
  line-height: 20px;
  font-family: IBM Plex Sans Medium;
}

.de-amount-bal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.de-amount-bal-text {
  color: #23262f;
  font-size: 14px;
  line-height: 20px;
  font-family: IBM Plex Sans Medium;
}

.de-modal-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 30px;
}

.de-modal-btn-group .de-btn-percentage {
  color: #23262f;
  font-size: 14px;
  line-height: 16px;
  font-family: IBM Plex Sans Medium;
  border: 1.5px solid #f5f5f5;
  border-radius: 4px;
  max-width: 130px;
  width: 100%;
  padding: 12px;
}

.de-modal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 21px;
}

.de-left-content {
  color: #777e90;
  font-size: 14px;
  line-height: 20px;
  font-family: IBM Plex Sans Medium;
}

.de-stake-modal-para {
  color: #23262f;
  font-size: 16px;
  line-height: 20px;
  font-family: IBM Plex Sans Medium;
  margin: 0px 0px 20px;
}

.de-footer-btn-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.de-footer-btn-group .btn-modal-footer {
  max-width: 140px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #c2d4ff;
  font-family: "poppins-semibold";
}

.de-modal-btn-group .de-btn-percentage:focus {
  color: #3772ff;
  border-color: #3772ff;
}

.de-modal-btn-group .de-btn-percentage:hover {
  background-color: transparent;
}

.de-inner-content {
  font-family: IBM Plex Sans Medium;
  font-size: 14px;
  line-height: 20px;
}

.de-footer-btn-group .btn-modal-footer:first-child {
  margin-right: 10px;
}

.staking-logo-svg {
  height: 25px;
  width: 25px;
}

.staking-modal-icon-btn {
  padding: 0px !important;
}

.staking-amount-field {
  height: 40px;
  width: 586px;
}

/* start deposite modal 1 css--------------------------------- */
.de1-modal-content .de1-select-para {
  color: #23262f;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 32px 0px;
  font-family: "Poppins-Medium";
}

.de1-modal-btns-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.de1-modal-btns-group .btn-de1-modal {
  width: 100%;
  background: #3772ff;
  border-radius: 0px;
  max-width: 384px;
  height: 48px;
  margin-bottom: 8px;
  color: #fcfcfd;
  font-size: 16px;
  line-height: 16px;
  font-family: "DMSans-Bold";
  text-transform: capitalize;
}

.de1-modal-btns-group .btn-de1-modal:last-child {
  margin-bottom: 0px;
}

.de1-modal-btns-group .btn-de1-modal:hover {
  background: #3772ff;
}

.on-tron-text {
  margin: 8px 0px 32px 26px;
  color: #777e91;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins-Regular";
}

.de-bal-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.de-bal-main {
  background: #f4f5f6;
  border-radius: 1px;
  padding: 20px 32px;
}

.de-modal-main {
  width: 100%;
  max-width: 448px;
}

.de-bal-text {
  color: #777e91;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins-Regular";
}

.de-bal-left {
  font-size: 14px;
  line-height: 24px;
  color: #353945;
  font-family: "poppins-medium";
}

.de-bal-right {
  font-size: 16px;
  line-height: 24px;
  color: #23262f;
  font-family: "poppins-medium";
}

.de-add-text {
  color: #23262f;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: "poppins-medium";
  margin: 32px 0px 12px 0px;
}

.de-add-para {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #777e91;
  margin: 0px 0px 32px;
  font-family: "Poppins-Regular";
}

.de-add-print-box {
  background: #f4f5f6;
  border: 1.5px solid #b1b5c3;
  padding: 8px 16px;
  width: 100%;
  max-width: 314px;
  margin: 0px auto 32px;
  height: 40px;
}

.de-add-print-text {
  color: #141416;
  font-size: 14px;
  line-height: 24px;
  font-family: "poppins-medium";
}

.de-add-print-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.de-scan-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.de-scan-img {
  height: 160px;
  width: 160px;
  object-fit: cover;
}

.de-modal-footer-para {
  color: #777e91;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  font-family: "poppins-medium";
  margin: 32px 0px 0px;
}

.deposite-crypto-ncx-logo {
  display: none;
}

/* end deposite modal 1 css */

/*============================================ MEDIA QUERY ==================================================== */
@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1200px) and (max-width: 1535px) {
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
}

@media screen and (max-width: 1199px) {
  /* ---------------- Sign Up------------------ */
  .Victorlogo {
    width: 34.62px;
    height: 31.15px;
    object-fit: cover;
  }

  .Ncxlogo {
    height: 32px;
    object-fit: contain;
    max-width: 97.5px;
  }

  .logo1 {
    position: relative !important;
    margin-top: 0px;
    padding-top: 78px;
    margin-left: 0;
  }

  .login-text {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 28px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none !important;
  }

  .CompositionImg-box {
    display: none !important;
  }

  .CompositionImg {
    display: none !important;
  }

  .signup-row {
    display: block;
    height: auto !important;
    border: 0px;
  }

  .signup-col1 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }

  .signup-col2 {
    margin: 0px;
    padding: 0 32px;
  }

  .signup-google {
    width: 100%;
    min-width: 149px !important;
    min-height: 48px;
    box-shadow: none !important;
  }

  .signup-apple {
    width: 100%;
    min-width: 149px !important;
  }

  .border-bottom-signup {
    border-bottom: 1px solid black;
    width: 100%;
    max-width: 314px;
  }

  .signup-form {
    width: 100% !important;
  }

  .country-code {
    width: 100%;
    max-width: 100px;
    margin-left: 21px;
  }

  .number {
    width: 100%;
    max-width: 205px;
  }

  .signup-agreements {
    display: flex;
    width: 100%;
    max-width: 331px;
  }

  /* ------------------Sign Up Verify Otp ----------------- */
  .signup-verify-otp-main-content {
    margin-top: 108px;
  }

  .signup-verify-otp-text {
    width: 311px;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  /* .signup-verify-otp-text2 {
        margin-top: 48px !important;
    } */

  .radio-field {
    width: 100%;
    max-width: 380px;
    margin: auto;
    display: flex;
  }

  .radio-field-text {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }

  /* -----------------SignUp Security Code ------------------ */
  .signup-security-code-text {
    color: #23262f;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 40px !important;
  }

  .signup-mobile-code-text {
    color: #777e90;
  }

  .signup-email-code-text {
    color: #777e90;
  }

  .signup-security-otp-input-field {
    max-width: 65.75px;
  }

  /* ---------------- SignUp Authenticator ----------------------- */
  .signup-col2 {
    margin: 0px;
    padding: 0 32px;
    padding-bottom: 32px;
  }

  .download-authenticator {
    font-size: 24px;
    margin-top: 56px;
  }

  .download-authenticator-guide {
    font-size: 12px;
    color: #000000;
  }

  .add-key-authenticator {
    margin-top: 60px;
    font-size: 24px;
  }

  .google-qr-text {
    margin-top: 12px;
    color: #000000;
    font-size: 12px;
    text-align: justify;
    line-height: 28px;
  }

  .qr {
    width: 100%;
    margin: 0px;
    max-width: 89px;
    margin-top: 29px;
  }

  .qr-text {
    font-size: 14px;
    margin-top: 5px;
  }

  .six-digit-code-text {
    font-size: 24px;
  }

  .six-digit-code {
    margin-top: 24px;
  }

  .six-digit-code-field {
    width: 100%;
    max-width: 33px;
    height: 38px !important;
    margin-right: 16px !important;
  }

  .google-authenticator-submit-btn {
    width: 100%;
    max-width: 131px !important;
    margin-bottom: 32px !important;
  }

  /* ------------------------ SignUp Forgot Password ----------------- */
  .forgot-password-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin-top: 92px;
  }

  /* -----------------------------Security Verification ------------------- */
  .security-verification-text {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-top: 92px;
  }

  .security-verification-mail-text {
    text-align: center;
    justify-content: center;
  }

  .security-verification-mail-text2 {
    display: block;
  }

  .security-verification-otp-input-field {
    width: 100%;
    max-width: 65px;
    margin: 12px !important;
    /* background-color: #F4F5F6; */
    border-radius: inherit;
  }

  /* -----------------------------------New Password-------------------------------*/
  .new-password-text {
    margin-top: 92px !important;
  }

  /* ----------------------------------Sign In To NCX---------------------------------- */
  .sign-in-heading {
    margin-top: 87px !important;
  }
}

@media screen and (max-width: 899px) {
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .wcypto-title-main-box {
    flex-direction: column;
  }

  .wcrypto-title {
    margin-bottom: 20px;
  }

  .withdraw-crypto-table {
    min-width: auto;
  }

  .staking {
    padding: 32px;
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .exchange-body {
    background: #f1f2f4;
    margin-top: 16px;
  }

  .exchange-drawer-btn {
    display: flex !important;
    width: 100% !important;
    background-color: #fcfcfd !important;
    justify-content: space-between !important;
    padding: 16px !important;
    text-align: center !important;
    border-bottom: 2px solid #e6e8ec;
  }

  .exchange-drawer-buy-btn {
    width: 100% !important;
    max-width: 165px !important;
    background-color: #28b594 !important;
    border-radius: 0px !important;
  }

  .exchange-drawer-btn-buy-sell-btc {
    text-transform: capitalize !important;
    color: #fcfcfd !important;
  }

  .exchange-drawer-sell-btn {
    width: 100% !important;
    max-width: 165px !important;
    background-color: #f84343 !important;
    border-radius: 0px !important;
  }

  /* ---------------------------Exchange Header------------------------------ */
  .exchange-body > div > div {
    max-width: 343px !important;
    margin-top: 0px !important;
  }

  .exchange-body .exchange-header-card-content {
    max-width: 343px;
    margin: auto;
    padding-bottom: 0px;
  }

  .exchange-header-content2 {
    margin-left: 0px !important;
  }

  .exchange-header-main {
    width: 100% !important;
    max-width: 343px !important;
    /* background-color: #000000 !important; */
  }

  .exchange-header-change {
    padding: 0px !important;
    border-right: 0px !important;
  }

  .exchange-header-content1 {
    padding: 0px !important;
  }

  .exchange-header-content1-coin1 {
    margin-top: 16px !important;
    margin-bottom: 4px !important;
  }

  .exchange-header-content1-price1 {
    margin-top: 8px !important;
  }

  .exchange-header-arrow-down-icon {
    margin-left: 161px !important;
    margin-top: 8px !important;
  }

  /* ------------------------------------Mobile Tabs---------------------------------- */
  .mobile-tabs button {
    min-height: auto !important;
    min-width: auto !important;
    text-transform: capitalize !important;
    margin: 16px 0px !important;
  }

  .mobile-tabs #simple-tab-0 {
    font-family: DMSans-Bold !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: capitalize !important;
    width: 63px !important;
    height: 28px !important;
    padding: 6px 12px !important;
  }

  .mobile-tabs #simple-tab-1 {
    font-family: DMSans-Bold !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: capitalize !important;
    width: 121px !important;
    height: 28px !important;
    padding: 6px 12px !important;
  }

  .mobile-tabs #simple-tab-2 {
    font-family: DMSans-Bold !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    text-transform: capitalize !important;
    width: 71px !important;
    height: 28px !important;
    padding: 6px 12px !important;
  }

  .mobile-tabs [role="tablist"] button[aria-selected="true"] {
    background: #23262f !important;
    color: #fcfcfd !important;
    border: 0 !important;
  }

  .mobile-tabs .MuiTabs-indicator {
    display: none !important;
  }

  .mobile-tabs {
    padding-left: 16px;
    padding-right: 16px;
  }

  .mobile-tabs .MuiTabs-flexContainer {
    justify-content: space-between;
  }

  .tabsmobilebox .MuiBox-root.css-19kzrtu {
    padding: 0px 16px 16px 16px;
  }

  .exchange-mobile-timeline {
    max-width: 343px !important;
  }

  /* -----------------------------Exchange Graph------------------------------- */

  .exchange-graph-card {
    width: 343px !important;
  }

  .exchange-mobile-timeline {
    align-items: center !important;
    background-color: #fcfcfd !important;
    padding: 16px !important;
  }

  .exchange-graph-timeline {
    margin-top: 1px !important;
  }

  .exchange-graph {
    height: 356px !important;
  }

  .exchange-graph-timeline-content2 {
    margin-left: 0 !important;
  }

  .exchange-graph-timeline-tradingView {
    width: 109px !important;
    margin-left: 8px !important;
  }

  .exchange-select-timeline .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0px;
  }

  .exchange-graph-timeline-tradingView-btn {
    text-transform: capitalize !important;
    font-style: normal !important;
    font-family: DMSans-Bold !important;
    font-size: 11px !important;
    background-color: #e6e8ec !important;
    color: #23262f !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }

  .exchange-graph-timeline-depth-btn {
    margin-left: 8px !important;
  }

  /* -------------------------Middle bottom Table Mobile--------------------- */

  .middle-bottom-table {
    margin-top: 12px !important;
  }

  .exchange-body .middle-bottom-table-card-content {
    padding: 0px !important;
  }

  .exchange-middle-bottom-table-card {
    width: 343px !important;
  }

  .exchange-mobile-dropdown {
    margin: 16px 16px 0px 16px !important;
  }

  .exchange-mobile-dropdown-text {
    border: 1px solid #e6e8ec !important;
    font-family: Poppins-SemiBold !important;
    font-style: normal !important;
    font-size: 12px !important;
    line-height: 20px !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
  }

  .MiddleBottomTable [role="tabpanel"] div {
    padding: 0px 0px 12px 16px !important;
  }

  .middle-bottom-table tr th {
    padding: 4px 0px 4px 0px !important;
  }

  .middle-bottom-table tr th:nth-child(1) {
    min-width: 72px !important;
    padding-right: 8px !important;
  }

  .middle-bottom-table tr th:nth-child(2) {
    min-width: 136px !important;
    padding-right: 8px !important;
  }

  .middle-bottom-table tr th:nth-child(3) {
    min-width: 152px !important;
    padding-right: 8px !important;
  }

  .middle-bottom-table tr td {
    padding-top: 12px !important;
  }

  /* ----------------------------Exchange Buy Sell Form--------------------- */
  .exchange-drawer-form {
    padding: 16px;
    padding-bottom: 32px;
  }

  .exchange-form-buy {
    margin: 0px !important;
  }

  .exchange-drawer-form-buy-sell-btn {
    width: 100% !important;
    max-width: 165px !important;
    border-radius: 0px !important;
    text-transform: capitalize !important;
    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 12px 69px !important;
  }

  /* ------------------------Exchange Left Side Table----------------- */
  .exchange-main-table {
    background-color: #fcfcfd !important;
    min-width: 343px !important;
    overflow: hidden !important;
  }

  .exchange-main-table > div {
    max-width: 343px !important;
    margin: 0px !important;
  }

  .exchange-table-icons img {
    /* width: 100%; */
    width: 32px !important;
    height: 32px !important;
  }

  .left-side-table-dropdown {
    margin-left: 81px !important;
    background-color: #f1f2f4 !important;
  }

  .red-green-table table tr th:nth-child(1) {
    min-width: 52px !important;
    padding: 4px 73px 4px 16px !important;
  }

  .red-green-table table tr th:nth-child(2) {
    min-width: 56px !important;
    padding: 5px 73px 4px 0px !important;
    text-align: right !important;
  }

  .red-green-table table tr th:nth-child(3) {
    min-width: 56px !important;
    padding-right: 16px !important;
  }

  .red-green-table table tr td:nth-child(1) {
    min-width: 52px !important;
    padding: 4px 73px 4px 16px !important;
  }

  .red-green-table table tr td:nth-child(2) {
    min-width: 56px !important;
    padding: 5px 73px 4px 0px !important;
    text-align: left !important;
  }

  .red-green-table table tr td:nth-child(3) {
    padding-right: 16px !important;
  }

  /* --------------------------Right Side Table--------------------- */

  .red-green-table > div {
    overflow: unset;
  }

  .exchange-right-side-table-card {
    width: 343px !important;
  }

  .table2-tabs #simple-tab-0 {
    margin-left: 0px !important;
  }

  .trades-tab-table p {
    background: #fcfcfd !important;
  }

  .right-side-table-search > div {
    max-width: 311px !important;
    margin: 12px 16px !important;
  }

  .table2 [role="tabpanel"] div {
    padding: 0px !important;
  }

  .table2-tabs {
    padding: 16px 16px 0px 16px !important;
  }

  .right-side-table-data table {
    max-width: 343px !important;
  }

  .right-side-table-data tr th:nth-child(1) {
    min-width: 80px !important;
  }

  .right-side-table-data tr th:nth-child(2) {
    min-width: 48px !important;
  }

  .right-side-table-data tr th:nth-child(3) {
    min-width: 48px !important;
    text-align: right !important;
  }

  .right-side-table-data tr td:nth-child(1) {
    min-width: 80px !important;
    text-align: left !important;
  }

  .right-side-table-data tr td:nth-child(2) {
    min-width: 48px !important;
  }

  .right-side-table-data tr td:nth-child(3) {
    min-width: 64px !important;
    text-align: right !important;
  }

  .exchange-mobile-form-head {
    display: flex !important;
    justify-content: space-between !important;
  }

  .exchange-mobile-form-text {
    color: #777e90 !important;
    font-family: Poppins-SemiBold;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  /* ------------------ Buy Crypto6 And Sell Crypto5--------------------- */

  .sell-crypto5-table tr td:first-child {
    display: none !important;
  }

  .sell-crypto5-table tr th:first-child {
    display: none !important;
  }

  .bitcoin-img-text-box {
    display: flex !important;
  }

  .bitcoin-text {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .sell-crypto5-table {
    margin: 0px 23px 0px 23px;
  }

  .wc-top-btns-group .btn-wcypto:last-child {
    margin-right: 0px;
    display: none;
  }

  .wcypto-title-main-box {
    flex-direction: column;
  }

  .wc-top-btns-group .btn-wcypto {
    height: 40px;
    min-width: 87px;
  }

  .withdraw-crypto-table td:nth-child(3) {
    display: none;
  }

  .withdraw-crypto-table td:nth-child(4) {
    display: none;
  }

  .withdraw-crypto-table th:nth-child(3) {
    display: none;
  }

  .withdraw-crypto-table th:nth-child(4) {
    display: none;
  }

  .withdraw-crypto-table {
    min-width: initial;
  }

  .wcrypto-title {
    display: none;
  }

  .wcrypto-main {
    background-color: transparent;
  }

  .withdraw-crypto-table-rows td {
    max-width: 80%;
  }

  .withdraw-crypto-table-rows th {
    max-width: 69px;
  }

  .wc-top-btns-group .btn-wcypto:nth-child(3) {
    margin-right: 0px;
  }

  .wcypto-top-box {
    padding: 16.5px;
    width: 100%;
    max-width: 100%;
  }

  .wcrypto-title-bal {
    font-size: 32px;
    line-height: 40px;
    color: #23262f;
    font-family: "DMSans-Bold";
    display: block;
    margin-bottom: 20px;
  }

  .wc-bal-sm-box {
    background-color: #fcfcfd;
    padding: 16px;
    margin-top: 32px;
    border-radius: 4px;
  }

  .wcypto-bal-box {
    margin-top: 0px;
    max-width: 268px;
  }

  .sm-mt-24 {
    margin-top: 24px;
  }

  .sm-btns-group {
    margin: 0px 50px;
  }

  .wcypto-bal .wcypto-bal-title {
    font-size: 10px;
    line-height: 16px;
  }

  .wcypto-bal .wcypto-bal-price {
    font-size: 16px;
    line-height: 24px;
  }

  .wc-fund-serchbox-main {
    display: none;
  }

  .wc-fiat-bal-box {
    margin: 16px auto;
    padding: 16px;
    background-color: #fcfcfd;
    border-radius: 4px;
    width: 100%;
    max-width: 93%;
  }

  .withdraw-crypto-th-currency {
    /* padding-right: 56px !important;
        max-width: 174px; */
    padding-right: 27px !important;
    max-width: 150px;
  }

  .wc-us-doller-text {
    display: none;
  }

  .withdraw-crypto-table-rows:hover .hover-show-btn-box {
    display: none;
  }

  .withdraw-crypto-table th:nth-child(5) {
    display: none;
  }

  .withdraw-crypto-th-balance:nth-child(3) {
    padding-right: 0px;
  }

  .withdraw-crypto-th-balance:nth-child(2) {
    max-width: 99px;
    padding-right: 52px !important;
  }

  .three-dots-img {
    display: block;
  }

  .withdraw-crypto-table-rows td:nth-child(3) {
    display: block;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }

  .withdraw-crypto-table-rows td:nth-child(5) {
    display: none;
  }

  .withdraw-crypto-td-balance:nth-child(2) .withdraw-crypto-td-balance {
    padding-right: 0;
  }

  .withdraw-crypto-td-balance {
    text-align: start !important;
  }

  .wc-funds-table {
    width: 100%;
    max-width: 93% !important;
    margin: 0px 16px 30px !important;
    padding: 16px !important;
  }

  .bitcoin-text {
    display: block !important;
  }

  .wc-mobile-btn {
    display: block !important;
  }

  .wc-modal-main {
    width: 100% !important;
    height: 100vh !important;
    max-width: 100% !important;
    overflow: auto !important;
  }

  .wc-modal-message {
    width: 100% !important;
    height: 100vh !important;
    max-width: 100% !important;
    overflow: auto !important;
  }

  .by10-modal-close {
    margin: 0px !important;
  }

  .wc-sm-dropdown
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    box-shadow: none !important;
    border: 1px solid #d8dde1;
  }

  /* ------------------------------------------------Staking------------------------------------------------ */
  .staking-head {
    padding: 0px !important;
  }

  .staking-head-inner {
    width: 100%;
    max-width: 375px;
    margin: auto !important;
    padding: 16px 32px 32px 32px !important;
  }

  .staking-head-balance {
    display: inline-block;
    width: 100% !important;
    max-width: 143.5px !important;
  }

  .staking-head-balance-text {
    font-family: Poppins-Medium !important;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    margin-bottom: 4px;
    line-height: 16px;
  }

  .staking-head-balance-usd {
    font-family: Poppins-Medium !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .staking-head-rewards {
    display: inline-block;
    width: 100% !important;
    max-width: 143.5px !important;
  }

  .staking-unstake-btn {
    display: block !important;
  }

  .staking-btn {
    width: 100%;
    min-width: 375px;
    padding: 28.5px 33px 28.5px 156px;
  }

  .staking-assets {
    padding: 0px !important;
    margin: 0px !important;
  }

  .staking-assets-text {
    margin-left: 31px;
    /* margin-bottom: 32px !important; */
    padding: 0px !important;
  }

  .staking-coin-svg {
    height: 40px !important;
    width: 40px !important;
  }

  .staking-coin-name-mobile {
    font-family: "IBM Plex Sans medium";
  }

  .staking-card-data {
    display: flex;
    justify-content: space-between;
  }

  .staking-mobile-table-container .staking-card-data p {
    margin: 0px 0px 8px 0px;
  }

  .staking-mobile-table-container .staking-card-data p:first-child {
    font-family: IBM Plex Sans Bold;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }

  .staking-mobile-table-container .staking-card-data p:last-child {
    font-family: "IBM Plex Sans medium";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  }

  .staking-transaction-main {
    display: none !important;
  }

  .staking-bottom-text {
    display: none !important;
  }

  /* ---------------Deposite Modals---------- */
  .deposite-modal {
    width: 100%;
    height: 100vh;
    max-width: 100%;
  }

  .de-modal-main {
    width: 100%;
    height: 100vh;
    max-width: 100%;
  }

  .deposite-crypto-ncx-logo {
    display: block;
    margin-top: 273px;
    margin-bottom: 202px;
    display: flex;
    justify-content: center;
  }

  .de-logo {
    height: 100%;
    max-height: 47px;
  }
}
