@media screen and (min-width: 1600px) and (max-width: 1919px) {}
@media screen and (min-width: 1366px) and (max-width: 1599px) {}
@media screen and (min-width: 1280px) and (max-width: 1365px) {}
@media screen and (min-width: 992px) and (max-width: 1279px) {}
/* ipad pro */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .chart-transiction-sec{
        display: flex;
    }
    .chart-transiction-sec .right-top-main,
    .chart-transiction-sec .transfer-sec{
        width: 50%;
        margin-top: 0;
    }
    .transfer-sec .admin-sub-title-main{
text-align: center;
    }
    .dashboard-left-main {
        flex: 0 0 230px;
        max-width: 230px;
        width: 100%;
        transition: 0.5s all;
    }
}

/* phone and tablets */
@media screen and (max-width: 991px) {}

/* tablets */
@media screen and (min-width: 768px) and (max-width: 991px) {}

/* phone */
@media (max-width: 767px) {
    .mob-header{
        padding: 5px 20px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);
        /* background: #FFF; */
        /* border-bottom: 1px solid #E5E7EB; */
    }
    .mob-header-left{
        display: flex;
        align-items: center;
    }
    .mob-header-left .admin-sidebar-logo {
        max-width: initial;
        height: 50px;
    }
    .dashboard-containt-main {
        padding: 70px 20px 20px;
        border-radius: 0px 0px 0px 0px;
    }
    .mob-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .profile-main,
    .profile-main img {
        width: 40px;
        height: 40px;
    }
    .dashboard-left-main{
        position: absolute;
        left: 0px !important;
        top: 0;
        bottom: 0;
        z-index: 9999;
    }
    .dashboard-left-main.active{
        left: -260px !important;
    }
    .overlay-section {
        display: block;
    }
    .mob-header{
        display: inline-flex;
    }
    .swap-box-content {
        flex-direction: column;
    }
    .swap-input-box {
        max-width: 100%;
    }
    .excha-card {
        padding: 15px;
    }
    .main-title-sec .admin-page-title {
        font-size: 22px;
        line-height: 32px;
    }
    .agency-wrapper {
        flex-direction: column;
    }
    .agency-right {
        border-top: 1px solid rgb(128 128 128 / 30%);
        padding: 10px 0px 0 0px;
        margin-top: 10px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: end;
        border-left: 0;
    }
    .agency-right .asking-price {
        margin-right: 5px;
    }
    .agency-right .asking-price-value {
        font-size: 15px;
        line-height: 24px;
    }
}