.view-main-container {
  /* margin-top: 10px; */
  padding: 0px 15px;
}
.view-container {
  margin-left: 70px;
  max-width: 1200px;
}
.view-heading-select {
  align-items: center;
  display: flex;
}
.view-heading {
  align-items: center;
  display: flex;
}
.view-heading img {
  margin-right: 24px;
}
.view-heading .view-heading-name {
  font-size: 40px;
}
.view-heading .view-heading-name span {
  color: #707a8a;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  padding-left: 16px;
}
.view-heading .view-icon {
  height: 48px;
  width: 48px;
}
.view-chart-tab {
  align-items: flex-start;
  display: flex;
}
.view-container-left {
  flex: 0 0 800px;
  max-width: 800px;
  min-width: 800px;
}
.price-inc-day {
  align-items: baseline;
  display: flex;
  margin-bottom: 24px;
}
.view-price-chart {
  color: #1e2329;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  margin-right: 10px;
}

.view-inc-chart {
  color: #0ecb81;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-right: 10px;
}
.sub-tab .main-tabbar {
  margin-top: 20px;
}

.main-tabbar .sub-tabs {
  margin: 0;
  padding: 0;
}
.main-tabbar-trending .trending-section-data {
  margin-top: 20px;
}
.sub-tabs .main-tabbar-trending {
  padding: 10px 0;
}
.sub-tab .sub-tab-heading {
  align-items: center;
  display: flex;
}

.sub-tab-heading .token-name {
  margin-bottom: 15px;
  font-size: 40px;
  align-items: center;
  color: #1e2329;
  display: flex;
  font-weight: 600;
  line-height: 48px;
}
.sub-tab-buttons {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.sub-tab-buttons .buttons {
  background-color: transparent;
  background-image: linear-gradient(180deg, #036134 0%, #12a3bb 100%);
  color: white;
  width: 120px;
  height: 40px;
}
.token-para {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 32px 0 0;
  padding: 0 -43px;
}
.token-para .token-big-txt {
  color: #1e2329;
  font-family: IBM Plex Sans SemiBold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
}
.token-para .token-small-txt {
  color: #707a8a;
  font-family: IBM Plex Sans Medium;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}
.view-container-right {
  width: 100%;
}
.trending-section-heading .section-heading {
  align-items: center;
  color: #1e2329;
  display: flex;
  font-weight: 600;
  line-height: 48px;
  font-size: 20px;
}
/* .trending-data {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
} */
.trending-data .sub-data-tab {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sub-data-tab .name-data {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
}
.trending-name-box .trending-data-icon {
  width: 48px;
  height: 48px;
}
.name-text-data {
  display: inline-block;
  margin-left: 15px;
}
.name-text-data .name-text {
  font-size: 20px;
  margin: 0 0 0 10px;
  font-family: IBM Plex Sans SemiBold;
}
.name-text-data .name-text-2 {
  font-size: 13px;
  margin: 0 0 0 10px;
  font-family: IBM Plex Sans SemiBold;
  color: rgb(119, 126, 145);
}

/* .table-image{
    width: 50px;
} */
.name-data img {
  width: 40px;
  height: 40px;
}
.trending-data {
  display: flex;
  border-radius: 4px;
  box-sizing: border-box;
  /* cursor: pointer; */
  justify-content: space-between;
  margin: 0;
  min-width: 0;
  padding: 12px 0;
}
.trending-data .trending-percents {
  box-sizing: border-box;
  color: #777e91;
  font-family: Poppins-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  min-width: 0;
}
.trending-percents .upper-price-box {
  align-items: baseline;
  display: flex;
  margin-bottom: 4px;
}
/* .trending-percents .trending-price {
  color: #777e90;
  font-family: IBM Plex Sans SemiBold;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  align-items: baseline;
  display: flex;
  margin-bottom: 4px; */
/* align-items: baseline;
    display: flex;
    margin-bottom: 4px; */

.trending-price .price {
  color: #777e90;
  font-family: IBM Plex Sans SemiBold;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0 12px 0 0;
}
.trending-percents .percent-positive {
  color: green;
}
.trending-percents .percent-negative {
  color: red;
}
.trending-price .percent-box {
  min-width: 95px;
}
.buy-sell-section {
  display: flex;
  justify-content: space-between;
  margin: 5px 40px 5px 5px;
  width: 95%;
}
.buy-sell-section .buy-sell-form {
  border-radius: 8px;
  box-shadow: 0 1px 6px #00000038;
  padding: 15px;
  width: 48.5%;
}

.market-section-title {
  padding: 10px 0;
}
.buy-sell-section .token-buy-sell-title {
  align-items: center;
  color: #1e2329;
  display: flex;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
}
.percent-box .percent-positive{
  color: green;
  font-size: 14px;
}
.percent-box .percent-negative{
  color: red;
  font-size: 14px
}
/* responsive */

@media screen and (max-width: 375px) {
  .view-container {
    margin: 0px;
    max-width: 370px;
  }
  .view-container-left {
    max-width: 370px;
  }
  .view-chart-tab {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) and (min-width: 765px) {
  .view-container {
    margin: 0px;
    max-width: 768px;
  }
  .view-container-left {
    flex: 0 0 200px;
  }
  .view-chart-tab {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}

.dialog-box{
 
  min-height: 350px;
 max-height: 100vh;
}
.modal-form{
padding: 20px;
}

 .modal-form .dialog-title{

  text-align: center;
  font-weight: bold;
  position: relative;
}


.model-close-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.sub-tab-buttons .trade-buttons{
  width: 100%;
}
.model-close-icon{

}