.admin-sidebar-logo-main {
    padding: 5px 0px;
    display: flex;
    justify-content: center;
}
.admin-sidebar-logo {
    width: 100%;
    max-width: 180px;
    object-fit: contain;
    margin: auto;
    display: flex;
    order: 2;
}
.admin-sidebar-list-main {
    margin-top: 15px;
}
.admin-sidebar-list-main .admin-sidebar-list {
    padding: 0px;
}
.dashboard-left-main{
    background-image: linear-gradient(72.47deg,#3fe398 22.16%,#32b0c4 76.47%);
}
.admin-sidebar-list-main .admin-sidebar-link {
    font-size: 14px;
    line-height: 21px;
    font-family: "Poppins-Medium";
    color: #ffffff;
    display: flex;
    padding: 10px 12px 10px;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
    text-decoration: none;
}
.admin-sidebar-list-main .admin-sidebar-link.active {
    color: #fff;
    border-radius: 4px;
    background: #32b0c4;
}
.admin-sidebar-icons {
    margin-right: 8px;
    display: flex;
}