/* / HEAD CSS / */
/* / define font face / */

/* / MID CSS / */

/* start notification page */
.noti-main {
  max-width: 1120px;
  background-color: #fcfcfd;
  margin: 32px auto;
  padding: 40px;
  margin-top: 0px;
}

.noti-main-title {
  font-size: 32px;
  line-height: 40px;
  /* color: #141416; */
  font-family: DMSans-Bold;
}

.noti-right-btn-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noti-top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #353945;
  padding-bottom: 32px;
}

.noti-right-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noti-right-btn-box .btn-noti,
.noti-right-btn-box .btn-noti:hover {
  /* color: #141416; */
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  height: 40px;
  border: 1.5px solid #b1b5c3;
  border-radius: 0px;
  text-transform: inherit;
  font-family: "DMSans-Bold";
}

.noti-right-btn-box .btn-mark-noti {
  margin-right: 16px;
  min-width: 138px;
}

.btn-clear-noti {
  max-width: 87px;
}

.noti-round-box {
  height: 48px;
  width: 48px;
  border: 2px solid #b1b5c3;
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noti-inner-title-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noti-content-box {
  display: flex;
  max-width: 602px;
  width: 100%;
  padding: 32px 0px 25px;
  border-bottom: 1px solid #353945;
}

.noti-content-inner {
  display: flex;
}

.noti-inner-title {
  color: #23262f;
  font-size: 16px;
  line-height: 24px;
  font-family: "poppins-semibold";
  margin-bottom: 8px;
}

.noti-date-time {
  /* color: #777E91; */
  font-size: 12px;
  line-height: 20px;
  font-family: "poppins-medium";
  display: flex;
  align-items: center;
}

.noti-content-para-box .noti-para {
  /* color: #777E91; */
  font-size: 12px;
  line-height: 20px;
  font-family: "poppins-medium";
}

.noti-img {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.noti-bottom-box .noti-content-box:last-child {
  border-bottom: 0px;
}

.noti-more-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 602px;
}

.noti-right-btn-box .btn-leren-noti {
  max-width: 132px;
  margin: 32px 0px;
}

.noti-cal-img {
  margin-left: 12px;
}

.noti-bottom-box {
  display: flex;
}

.noti-filter-text {
  font-size: 24px;
  line-height: 32px;
  font-family: "poppins-semibold";
  color: #23262f;
  margin-bottom: 13px;
  margin-left: 5px;
}

.noti-right {
  max-width: 352px;
  width: 100%;
  margin-left: 80px;
}

.nori-rignt-main {
  padding: 32px 0px 25px;
}

.noti-right-btn-box .btn-noti-select {
  max-width: 94px;
  margin-right: 12px;
}

.noti-right-btn-box .btn-noti-unselect {
  max-width: 111px;
}

.noti-select-btn-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.noti-checkbox span {
  padding: 0px;
  margin: 0px 0px 24px 12px;
}

.noti-time-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noti-time-box .green-round {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #28b594;
  margin-left: 16px;
  /* border: 8px solid #28B594; */
}

.noti-mobile-dropdown {
  display: none;
}

/* .noti-checkbox .MuiSvgIcon-root{
    color:#9e9e9eb0;
} */
/* end notification page */

.by7-amout-box {
  margin-bottom: 48px !important;
}

.deposite-ref-text {
  font-size: 16px;
  line-height: 24px;
  color: #23262f;
  margin: 32px 0px 12px;
  font-family: "Poppins-semibold";
}

.deposite-payment-para {
  color: #777e91;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins-Regular";
}

.deposite-btn-box {
  display: flex;
  justify-content: flex-end;
}

.wp-btns-box .wp-btns {
  height: 48px;
  width: 100%;
  max-width: 184px;
  border: 1.5px solid #b1b5c3;
  border-radius: 0px;
  color: #141416;
  font-size: 16px;
  line-height: 16px;
  font-family: DMSans-bold;
  text-transform: capitalize;
  margin-top: 24px;
}

/* start withcrypto page */
/* .wcrypto-main {
    max-width: 1290px;
    width: 100%;
    background-color: #FCFCFD;
    margin: 20px auto;
}

.wcypto-top-box {
    padding: 40px 33px 32px 32px;
}

.wcrypto-title {
    font-size: 32px;
    line-height: 40px;
    color: #23262F;
    font-family: 'DMSans-Bold';
}

.wc-top-btns-group {
    display: flex;
}

.wcypto-title-main-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wc-top-btns-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wc-top-btns-group .btn-wcypto {
    height: 48px;
    width: 100%;
    min-width: 150px;
    border: 1.5px solid #B1B5C3;
    border-radius: 0px;
    color: #141416;
    font-size: 16px;
    line-height: 16px;
    font-family: DMSans-bold;
    text-transform: capitalize;
    margin-right: 10px;
}

.wc-top-btns-group .btn-wcypto:last-child {
    margin-right: 0px;
}

.btn-wcypto:active,
.btn-wcypto:hover {
    background-color: #3772FF !important;
    color: #fff;
}

.wcypto-bal-box {
    max-width: 884px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
}

.wcypto-bal .wcypto-bal-title {
    color: #353945;
    font-size: 14px;
    line-height: 24px;
    font-family: poppins-medium;
    margin-bottom: 4px;
}

.wcypto-bal .wcypto-bal-price {
    font-size: 24px;
    line-height: 32px;
    color: #23262F;
    font-family: poppins-semibold;
}

.wc-fiat-bal-box {
    padding: 32px;
}

.wc-fiat-title-box .wc-fiat-title {
    font-size: 32px;
    line-height: 40px;
    color: #23262F;
    font-family: 'DMSans-Bold';
} */

/* end withcrypto page */
/* start header notification popup */
.header-noti-title {
  color: #23262f;
  font-size: 24px;
  line-height: 32px;
  font-family: Poppins-SemiBold;
}

.header-noti-main {
  padding: 16px;
  width: 100%;
  min-width: 318px;
  border-radius: 0px;
  background: #fcfcfd;
  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
}

.header-noti-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-noti-box {
  padding: 16px 0px;
  border-bottom: 1px solid #b1b5c3;
}

.header-noti-main .header-noti-box:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.header-green-round {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #28b594;
  margin-left: auto;
}

.header-noti-inner-title {
  color: #23262f;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-semibold";
  margin-bottom: 4px;
}

.header-noti-time {
  color: #777e91;
  font-size: 10px;
  line-height: 16px;
  font-family: "Poppins-Regular";
}

.header-noti-btn-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.header-noti-btn-box .btn-left-noti {
  /* background: #3772FF; */
  width: 135px;
  height: 40px;
  /* color: #fff; */
  border-radius: 0px;
  margin-right: 16px;
  font-size: 14px;
  line-height: 16px;
  /* color: #FCFCFD; */
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

.header-noti-btn-box .btn-left-noti:hover {
  /* color: #fff; */
  background: #3772ff;
}

.header-noti-popup .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px;
  top: 81px !important;
  /* left: 807px!important; */
  margin-left: -96px;
  overflow: initial;
}

.header-noti-popup .css-3bmhjh-MuiPaper-root-MuiPopover-paper:after {
  content: "";
  position: absolute;
  right: 53px;
  top: -24px;
  border: 12px solid #fcfcfd;
  border-left: 12px solid transparent;
  border-top: 12px solid transparent;
  border-right: 12px solid transparent;
}

.header-noti-btn-box .btn-right-noti {
  /* color: #23262F; */
  border: 1.5px solid #b1b5c3;
  width: 135px;
  height: 40px;
  border-radius: 0px;
  font-size: 14px;
  line-height: 16px;
  font-family: Poppins-Regular;
  text-transform: capitalize;
}

.header-profile-title-box {
  display: flex;
  padding: 10px 0px 0px 0px;
}

.header-profile-inner-title {
  font-size: 14px;
  line-height: 16px;
  color: #353945;
  margin-bottom: 2px;
  font-family: "DMSans-Bold";
}

.header-profile-time {
  color: #777e91;
  font-size: 10px;
  line-height: 16px;
  font-family: "Poppins-Regular";
}

.user-profile-img {
  margin-right: 16px;
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.header-profile-box {
  border-bottom: 1px solid #b1b5c3;
}

.header-profile-main .header-profile-content:last-child .header-profile-box {
  border-bottom: 0px;
}

.header-profile-main {
  padding: 0px 16px;
  max-width: 256px;
  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
  background-color: #fff;
}

.profile-switch {
  padding-top: 0px !important;
  position: absolute;
  right: 0px;
}

.header-proile-list {
  padding-right: 29px;
}

.header-profile-popup .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px;
  top: 70px !important;
  /* left: 1102px!important; */
  margin-left: 9px;
  overflow: initial;
}

.header-ncx-card-popup .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  border-radius: 0px;
  top: 70px !important;
  /* left: 367px!important; */
  overflow: initial;
  margin-left: -63px;
}

.ncx-card-title {
  border-bottom: 1px solid #b1b5c3;
  padding: 15px 0px;
}

.ncx-card-title .ncx-card-text {
  font-size: 14px;
  line-height: 16px;
  /* color: #141416; */
  font-family: "DMSans-Bold";
}

.header-ncx-card-main {
  padding: 0px 16px 0px 16px !important;
  min-width: 256px !important;
  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
}

.header-ncx-card-main .ncx-card-title:last-child {
  border-bottom: 0px;
}

/* end header notification popup */

/* start profile page dropdown */
.mobile-dropdown {
  display: none;
}

/* end profile page dropdown */
.ap11-form-group .api-form-control input {
  background: #f4f5f6;
}

.eye-img {
  position: absolute;
  height: 20px;
  width: 20px;
  object-fit: contain;
  right: 16px;
  top: 12px;
}

.change-pass-control {
  position: relative;
}

.forgot-password-input-field .forgot-password-field ::placeholder {
  font-size: 14px;
  line-height: 24px;
  color: #141416;
  opacity: 1;
  font-family: "Poppins-medium";
}

.input-field .signup-control ::placeholder {
  font-size: 14px;
  line-height: 24px;
  /* color: #141416; */
  /* opacity: 1; */
  font-family: "Poppins-medium";
}

.signup-agreements span {
  padding: 0px 12px 0px 0px;
}

.signup-sm-col2 {
  padding-bottom: 32px !important;
}

.pro-key-img {
  margin: 0px 6px;
}

.header-border {
  border-bottom: 0px !important;
}

.lang-currancy-box {
  display: flex;
  align-items: center;
  padding: 16px 0px 0px;
}

.hlanguage-box {
  width: 100%;
  min-width: 141px;
}

.hcurrancy-box {
  width: 100%;
  min-width: 142px;
}

.usd-middle-border {
  border: 1px solid #b1b5c3;
  margin: 0px 24px;
  height: 109px;
  margin-top: -16px;
}

.lang-currancy-box .usd-midgrey-text {
  font-size: 14px;
  font-family: DMSans-Bold;
  line-height: 16px;
  color: #777e91;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #b1b5c3;
}

.lang-currancy-box .usd-black-text {
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 16px;
  color: #23262f;
  font-family: DMSans-Bold;
  margin-bottom: 16px;
  border-bottom: 1px solid #b1b5c3;
}

.lang-currancy-box .usd-langrey-text {
  font-size: 12px;
  font-family: "poppins-regular";
  line-height: 16px;
  color: #777e91;
  margin-bottom: 16px;
}

.grey-black-dot-img {
  height: 8px;
  width: 8px;
  object-fit: contain;
  margin-right: 14px;
}

.hlanguage-box p:last-child {
  border-bottom: 0px;
}

.header-usd-popup .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  top: 82px !important;
}

.header-usd-popup .header-usd-main .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
}

.va-table-td button {
  height: 10px;
  width: 16px;
  padding: 0px;
}

.va-table-td button svg {
  height: 18px;
  width: 20px;
}

.view-activity-table-main th {
  border-bottom: 0px;
  padding: 26px 16px 27px 16px;
  font-family: Poppins-Medium;
}

.view-activity-table-main td {
  border-bottom: 0px;
  font-family: Poppins-semibold;
}

.calendar-img {
  height: 12px;
  width: 13.33px;
  object-fit: contain;
  margin-left: 13.33px;
}

.va-bitcoin-img {
  vertical-align: inherit;
  margin-right: 12px;
  height: 32px;
  width: 32px;
  object-fit: contain;
}

.view-activity-tabs {
  min-height: 0px !important;
}

.view-activity-table-row {
  border-bottom: 1px solid #b1b5c3;
}

.view-activity-btn-group {
  padding-bottom: 20px !important;
}

.va-last-th {
  text-align: end !important;
}

.va-last-td {
  text-align: end !important;
}

.view-activity-table-main {
  overflow: hidden;
}

.header-icon {
  width: 16.09px;
  height: 23.92px;
  object-fit: contain;
}

.noti-blue-dot {
  position: relative;
}

.noti-blue-dot span {
  position: absolute;
  top: -2px;
  background-color: #bc8b23 !important;
}

.header-wallet-btn {
  width: 100%;
  max-width: 95px;
  height: 40px;
  text-transform: capitalize !important;
  /* background-color: red !important; */
  background-image: linear-gradient(
    to right,
    #d5a12b 0%,
    #9b6e19 51%,
    #d19c26 100%
  );
}

/* .btn-grad {
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; 
    color: #fff;
    text-decoration: none;
  } */

.login-link {
  margin-left: 8px !important;
}

.signup-social-link-button .signup-social-btn:first-child {
  margin-right: 0px;
}

.signup-down-arrow {
  position: absolute;
  right: 19px;
  top: 21px;
}

.signup-para {
  font-family: "Poppins-Medium";
}

.header-logo-box:hover {
  background-color: transparent !important;
}

.session-left-main {
  padding-bottom: 110px !important;
}
.header-usd-dropdown {
  position: relative;
}
.header-down-img {
  position: absolute;
  top: 4px;
  right: -29px;
  height: 8px;
  width: 8px;
  object-fit: contain;
}
/* / your css / */
/* end css */
/* / MEDIA QUERY / */
@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1200px) and (max-width: 1535px) {
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .apikey-row {
    flex-direction: column;
    margin: 25px;
  }

  .api-spacing {
    padding-top: 2px;
    padding-bottom: 8px;
  }

  .buycrypto-main8 {
    margin: 0px 32px;
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .noti-top-box {
    flex-direction: column;
    align-items: self-start;
    padding: 0px 32px 32px;
    border: 0px;
  }

  .noti-main {
    padding: 50px 0px 0px;
    margin: 0px;
  }

  .noti-content-box {
    padding: 32px;
  }

  .noti-inner-title-main {
    flex-direction: column;
    align-items: flex-start;
  }

  .noti-date-time {
    margin-bottom: 8px;
  }

  .noti-main-title {
    margin-bottom: 19px;
  }

  .noti-time-box {
    width: 100%;
  }

  .noti-time-box .green-round {
    margin-top: -10px;
  }

  .noti-cal-img {
    display: none;
  }

  .noti-right-btn-box .btn-leren-noti {
    max-width: 104px;
  }

  .nori-rignt-main {
    display: none;
  }

  .noti-mobile-dropdown {
    display: block;
    text-align: center;
  }

  .noti-bottom-box {
    display: flex;
    flex-direction: column-reverse;
  }

  .noti-right {
    margin-left: 0px;
    max-width: initial;
  }

  .noti-mobile-dropdown .noti-dropdown {
    border: 1.5px solid #b1b5c3;
    width: 311px;
    height: 48px;
    margin: auto;
  }

  .noti-dropdown
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 11px 21.5px 11px 12px;
  }

  .noti-mobile-dropdown label {
    line-height: 12px;
  }

  .noti-dropdown .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0px;
  }

  .noti-dropdown .MuiMenu-list {
    width: 400px;
  }

  .noti-dropdown .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .noti-select
    ul
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    opacity: 1;
    transform: none;
    min-width: 49px;
    max-height: 228px;
    width: 309px;
    top: 197px;
    left: 34px;
    transform-origin: 135px 0px;
  }

  .noti-dropdown .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0px;
  }

  .deposit-row {
    flex-direction: column;
  }

  .wp-btns-box .wp-btns {
    margin-bottom: 50px;
  }

  .deposite-btn {
    margin-bottom: 32px !important;
  }

  .sm-wp-left-content {
    width: 50%;
  }

  .sm-wp-middle-address {
    text-align: end;
  }

  .main {
    flex-direction: column;
  }

  /* start profile page dropdown */
  .mobile-dropdown {
    display: block;
    margin-bottom: 24px;
  }

  .mobile-dropdown .sm-pro-dropdown-control {
    width: 100%;
    min-width: 343px;
    height: 49px;
    background: #fcfcfd;
  }

  .sm-pro-dropdown-control .sm-pro-select {
    border-radius: 0px;
    border: 0px;
  }

  .mobile-dropdown
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 15px 12px 14px 16px;
    font-size: 14px;
    line-height: 16px;
    /* color: #141416; */
    font-family: "DMSans-Bold";
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .mobile-dropdown svg {
    display: none;
  }

  .mobile-dropdown .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .user-drop-img {
    margin-right: 8px;
  }

  /* end profile dropdown  */
  .apikey-row {
    flex-direction: column;
  }

  .api-spacing {
    padding: 16px 0px 0px;
  }

  .apikey-text-p br {
    display: block;
  }

  .apikey11-title-h5 {
    margin-left: 0px !important;
  }

  .apikey11-divider,
  .btn-enable-key {
    margin-bottom: 24px !important;
  }

  .ref-form-control input::placeholder {
    color: #23262f;
    font-size: 16px;
    line-height: 24px;
    color: #777e91;
    margin: 0px 0px 21.5px;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-drop-btn {
    margin: 19px 0px 40px 27px !important;
  }

  .input-number .sm-number-input {
    min-width: 204px;
  }
}
