.spot-trade-main {
  display: flex;
  padding: 50px 198px;
}

.trade-left {
  /* background: red; */
  flex: 1;
  /* display: flex; */
}

.trade-right {
  /* background: green; */
  width: 21.605%;
  max-width: 21.605%;
  flex: 0 0 21.605%;
  border: 1px solid #e6e6e6;
  padding: 16px;
}

.trade-inner-left {
  /* background: yellowgreen; */
  width: 27.56%;
  max-width: 27.56%;
  flex: 0 0 27.56%;
  border: 1px solid #e6e6e6;
  border-top: none;
}

.trade-inner-right {
  flex: 1;
  /* background: black; */
}

.trade-chart-sec {
  display: flex;
}

.trade-left-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
  border: 0.5px solid #e0e1e3;
  border-right: none;
}

.bitcoin-usdt-price {
  border-right: 1px solid #e0e1e3;
  padding: 0 24px 0 0;
  margin: 0 24px 0 0;
}

.bitcoin-usdt {
  display: flex;
  align-items: center;
}

.book-ic svg {
  width: 16px;
  height: 16px;
  transition: all 0.5s ease;
  margin: 0 4px 0 0;
}

.book-ic:hover svg path {
  fill: #000;
}

.bitcoin-usdt .utc-text {
  color: #111827;
  font-family: Poppins-Medium;
  font-size: 20px;
  line-height: 30px;
}

.coin-price-link {
  line-height: 17px;
  font-size: 12px;
  color: #707a8a;
  text-decoration: underline;
}

.bitcoin-vol {
  color: rgb(201, 148, 0);
  background-color: rgb(249, 244, 226);
  display: flex;
  align-items: center;
  max-width: 80px;
  padding-left: 5px;
}

.bitcoin-vol svg {
  width: 12px;
  height: 12px;
}

.vol-text {
  color: rgb(201, 148, 0);
  font-size: 12px;
  font-weight: 500;
  font-family: Poppins-Regular;
}

.bitcoin-vol .text-dash {
  margin: 0 5px;
}

.up-down-price-sec .up-down-text {
  /* color: #03A66D; */
  font-size: 16px;
  font-family: Poppins-Regular;
  line-height: 20px;
}

.up-down-price-sec {
  margin: 0 26px 0 0;
}

.trade-bitcoin-sec {
  display: flex;
  align-items: center;
}

.trade-bitcoin-sec .green-text {
  color: #03a66d !important;
}

.price-table-header .green-text {
  color: #03a66d;
}

.trade-bitcoin-sec .red-text {
  color: #cf304a !important;
}

.trade-bitcoin-sec .up-dow-black {
  color: #1e2329;
  font-size: 12px;
}

.change-text {
  font-size: 12px;
  line-height: 16px;
  color: #707a8a;
  /* margin-bottom: 2px !important; */
  font-family: Poppins-Regular;
}

.spot-tutorial {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #707a8a;
}

.spot-tutorial svg {
  width: 16px;
  height: 16px;
  margin: 0 4px 0 0;
}

.guidance-sec {
  display: flex;
}

.guid-sec {
  margin-left: 16px;
}

.trade-inner-left {
  padding: 16px;
}

.grid-table {
  display: flex;
  align-items: center;
}

.grid-table .grid-btn {
  min-width: 24px;
  height: 24px;
  background: transparent;
  padding: 0;
  margin-right: 10px;
}

.grid-btn svg {
  width: 24px;
  height: 24px;
}

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  padding: 0px;
  font-size: 14px;
  line-height: 24px;
  color: #23262f;
  font-family: poppins-medium;
  display: flex;
}

.form-control fieldset {
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}

.grey-down {
  position: absolute;
  top: 5px;
  right: 10px;
  height: 16px;
  width: 16px;
  object-fit: contain;
  z-index: -1;
  /* transform: translateY(-50px); */
}

.price-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.price-table-right {
  display: flex;
  align-items: center;
}

.table-menu-btn .MuiButtonBase-root {
  padding: 0;
}

.table-menu-btn .MuiButtonBase-root:hover {
  background-color: transparent;
}

.price-table-heade .dropdown-box {
  display: flex;
  align-items: center;
}

.price-table-wrap tr th,
.price-table-wrap tr td {
  padding: 15px 0px 0;
  font-size: 12px;
  font-family: "poppins-medium";
  color: #707a8a;
  border-bottom: none;
  line-height: 20px;
}

.price-table-wrap tr td {
  padding: 0px 0px 0 !important;
}

.price-table-wrap tr td:nth-child(1) {
  color: #cf304a;
}

.price-table-main .table-container {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.price-bottom-table tr td:nth-child(1) {
  color: #03a66d;
}

.table-head-text {
  font-size: 20px;
  line-height: 27px;
  font-family: "Poppins-Regular";
  color: #1e2329;
}

.grid-table .head-sub-text,
.price-table-right a.more-link {
  font-size: 12px;
  line-height: 15px;
  color: #707a8a;
  font-family: "Poppins-Regular";
  margin-left: 10px;
}

.arrow-red-icons {
  display: none;
}

.up-down-arrown img {
  width: 15px;
}

.trade-search-sec {
  position: relative;
}

.trade-search-sec .form-control {
  width: 100%;
}

.trade-search-sec .form-control input {
  padding: 5px 16px 5px 30px !important;
  color: #707a8a;
}

.trade-search-sec .form-control input::placeholder {
  color: #707a8a;
  font-size: 12px;
}

.trade-search-sec .search-icons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 14px;
  height: 14px;
}

.star-icons {
  width: 10.5px;
  height: 10.5px;
}

/* .trad-top-tab{
    height: 389px !important;
} */
.trad-top-tab .trad-top-tabs {
  min-height: 23px;
}

.trad-top-tabs .MuiButtonBase-root {
  max-width: max-content;
  min-width: max-content;
  min-height: 23px;
  height: 23px;
  padding: 4px;
  font-size: 12px;
  letter-spacing: 0;
  font-family: "Poppins-Medium";
  color: #707a8a;
  text-transform: uppercase;
}

.trad-top-tabs .Mui-selected,
.spot-tab-panel .cus-spot-inner-tab.Mui-selected {
  color: #c99400 !important;
}

.trad-top-tabs .MuiTabs-indicator {
  display: none;
}

/* .trade-right .trad-top-tab{
height: 23px;
} */
.trad-top-tab .MuiBox-root {
  border: none;
}

.cus-table-wrapper .MuiDataGrid-footerContainer {
  display: none;
}

.cus-tab-panel .MuiBox-root {
  padding: 0;
}

.cus-tab-panel .MuiDataGrid-columnSeparator,
.cus-tab-panel .MuiDataGrid-menuIcon {
  display: none !important;
}

.cus-table-wrapper .cus-datagrid {
  border: none;
}

.cus-datagrid .MuiDataGrid-columnHeaderTitle,
.cus-datagrid .MuiDataGrid-cell {
  font-size: 12px;
  color: #707a8a;
}

.cus-datagrid .MuiDataGrid-columnHeader,
.cus-datagrid .MuiDataGrid-columnHeaders,
.cus-datagrid .MuiDataGrid-cell {
  max-height: 23px !important;
  min-height: 23px !important;
  border: none !important;
  padding: 0;
}

/* .cus-datagrid .MuiDataGrid-cell{
    border: none !important;
} */
.cus-datagrid .MuiDataGrid-row {
  max-height: 23px !important;
  min-height: 23px !important;
}

.cus-datagrid .MuiDataGrid-columnHeaderTitleContainer:last-child {
  justify-content: end !important;
}

.cus-datagrid
  .MuiDataGrid-main
  [role="row"]
  .MuiDataGrid-columnHeader:last-child
  .MuiDataGrid-columnHeaderTitleContainer,
.cus-datagrid
  .MuiDataGrid-virtualScrollerRenderZone
  [role="row"]
  .MuiDataGrid-cell:nth-child(3),
.cus-datagrid
  .MuiDataGrid-main
  [role="row"]
  .MuiDataGrid-columnHeader:nth-child(2)
  .MuiDataGrid-columnHeaderTitleContainer,
.cus-datagrid
  .MuiDataGrid-virtualScrollerRenderZone
  [role="row"]
  .MuiDataGrid-cell:nth-child(2) {
  justify-content: end !important;
}

.cus-datagrid
  .MuiDataGrid-virtualScrollerRenderZone
  [role="row"]
  .MuiDataGrid-cell:nth-child(2) {
  color: #03a66d;
}

.cus-datagrid
  .MuiDataGrid-virtualScrollerRenderZone
  [role="row"]
  .MuiDataGrid-cell:nth-child(3) {
  color: #cf304a;
}

.cus-datagrid .MuiDataGrid-virtualScrollerRenderZone {
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .cus-table-wrapper .MuiDataGrid-virtualScrollerContent{
    height: 389px !important;
 } */
.trade-right .trad-top-tab {
  height: 360px !important;
}

.market-trade-tab {
  padding-top: 16px;
  border-top: 1px solid #e0e1e3;
}

.market-trade-tab .trad-top-tabs .MuiButtonBase-root {
  text-transform: capitalize;
  margin-right: 24px;
}

.trade-chart-main {
  height: 441px;
}

.trade-chart-main img {
  width: 100%;
}

.login-reg-tab {
  color: #707a8a;
  font-size: 12px;
  font-family: "Poppins-Medium";
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-reg-link {
  color: #f0b90b;
  font-size: 12px;
  font-family: "Poppins-Medium";
}

.trade-spot-tab-main .MuiTabs-indicator {
  background-color: #f0b90b;
  top: 0;
  bottom: initial;
}

.trade-spot-tab-main .Mui-selected {
  color: #1e2329 !important;
  background-color: #fff;
}

.cus-spot-tabs .cus-spot-tab {
  font-size: 14px;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
  min-width: 120px;
}

.trade-spot-tab-main .spot-tab-panel {
  background-color: #fff;
}

.spot-tab-panel .MuiBox-root {
  padding: 12px 16px;
}

.spot-tab-panel .cus-spot-inner-tab {
  padding: 4px;
  min-width: max-content;
  color: #707a8a !important;
  background: transparent;
  text-transform: capitalize;
}

.trade-spot-tab-main .spot-inner-tab {
  padding: 0;
}

.spot-inner-tab .MuiTabs-indicator {
  display: none;
}

.spot-inner-tab-panel .MuiBox-root {
  padding: 0;
}

.cus-spot-inner-tabs {
  min-height: 23px;
  max-height: 23px;
  border-bottom: 1px solid rgb(238, 240, 242);
}

.avbl-box .avbl-text {
  margin: 10px 0px 8px 0px;
  color: rgb(112, 122, 138);
  font-size: 12px;
  line-height: 16px;
}

.avbl-box .avbl-text span {
  margin: 0px 0px 0px 8px;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}

.limit-wrapper {
  display: flex;
}

.limit-wrapper .avbl-box {
  width: 50%;
}

.limit-wrapper .avbl-usdt-box {
  padding: 10px 16px 0 0;
}

.limit-wrapper .avbl-btc-box {
  padding: 10px 0 0 16px;
}

.avbl-box .price-input-grp {
  display: flex;
  margin: 16px 0px 8px;
  width: 100%;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  background-color: rgba(230, 232, 234, 0.6);
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(112, 122, 138);
}

.price-input-grp .price-start-label {
  flex-shrink: 0;
  /* margin-left: 8px; */
  min-width: 48px;
  font-size: 14px;
  color: rgb(71, 77, 87);
  display: flex;
}

.price-input-grp .price-text {
  margin: 0px;
  min-width: 0px;
  font-size: 12px;
  color: rgb(132, 142, 156);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-family: "Poppins-Regular";
}

.price-end-label .price-text {
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 14px;
  color: rgb(112, 122, 138);
}

.price-input-grp .price-box {
  width: 100%;
  /* padding: 0px 11px; */
}

.price-box .form-control {
  width: 100%;
}

.price-box .form-control input {
  padding: 9.45px 16px !important;
  border: 0px !important;
  text-align: right;
  color: rgb(30, 35, 41);
  font-size: 14px;
  font-family: "Poppins-Regular";
}

.price-input-grp .login-reg-link span {
  font-size: 12px;
  color: rgb(112, 122, 138);
}

.login-reg-btn {
  justify-content: center;
}

.step-box {
  border-color: rgb(71, 77, 87);
}

.prog-main .bn-slider-stepper {
  position: absolute;
  transform: translateX(-50%) rotate(45deg);
  background-color: rgb(255, 255, 255);
  color: rgb(112, 122, 138);
  width: 6px;
  height: 6px;
  border-radius: 2px;
  border: 2px solid rgb(234, 236, 239);
  z-index: 10;
  overflow: visible;
  cursor: pointer;
}

.prog-main .squreic {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid rgb(234, 236, 239);
  z-index: 10;
  transform: translateX(-50%) rotate(45deg);
  border-radius: 4px;
}

.prog-main .squreic.active {
  width: 15px;
  height: 15px;
  border: 4px solid rgb(43, 49, 57);
}

.prog-main-wrapper {
  display: flex;
  align-items: center;
  height: 25px;
}

.prog-main {
  /* margin: 4px 0 10px 0; */
  position: relative;
  /* -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center; */
  width: calc(100% - 14px);
  margin-left: 17px;
  /* height: 25px; */
}

.prog-main .RSPBprogressBar {
  height: 4px;
}

/* .prog-main .bn-slider-container{
    position: relative;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    width: calc(100% - 14px);
    margin-left: 7px;
    height: 25px;
  } */
.prog-main .prog-tooltip {
  /* position: absolute;
    top: 0px; */
  padding: 2px 4px;
  border-radius: 2px;
  background-color: rgb(43, 49, 57);
  color: rgb(255, 255, 255);
  font-size: 12px;
  z-index: 10;
  transition: visibility 0.2s ease 0s, opacity 0.2s ease 0s;
}

.prog-main .RSPBstep:first-child::after {
  content: "0%";
  position: absolute;
  width: 27px;
  height: 22px;
  background: #fff;
  color: #707a8a !important;
  padding: 2px;
  top: -36px;
  left: -20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins-Medium";
  border-radius: 2px;
  visibility: hidden;
  transition: all 0.3s ease;
}

.prog-main .RSPBstep:first-child:hover:after {
  visibility: visible;
}

.cus-arrow-drop {
  display: flex;
  position: relative;
}

.cus-arrow-drop .cus-arrow-ic {
  align-items: center;
  display: flex;
}

.cus-arrow-drop .cus-arrow-ic svg {
  width: 16px;
  height: 16px;
}

.cus-drop-list .drop-list-ul {
  background: #fff;
  border-radius: 4px;
  padding: 0;
  box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px,
    rgba(71, 77, 87, 0.08) 0px 7px 14px, rgba(24, 26, 32, 0.08) 0px 3px 6px;
}

.drop-list-ul .drop-list-li {
  padding: 12px 16px;
  max-height: 42px;
  font-size: 14px;
  font-family: "Poppins-Regular";
}

.cus-arrow-drop .drop-list-li.acive {
  color: #f0b90b;
}

.cus-arrow-drop .cus-drop-list {
  position: absolute;
  top: 75%;
  right: -70%;
  z-index: 99;
  display: none;
}

.cus-arrow-drop .drop-list-li:hover {
  color: #f0b90b;
  background: #f5f5f5;
}

.cus-arrow-drop:hover .cus-drop-list {
  display: block;
}

.drop-tooltip {
  display: flex;
  margin-left: 5px;
}

.drop-tooltip svg {
  width: 20px;
}

.MuiTooltip-tooltip {
  max-width: 200px;
  font-family: "Poppins-Regular";
  font-size: 12px;
  line-height: 16px;
}

.borrow-sec ul {
  padding: 0;
  display: flex;
}

.borrow-sec ul li {
  width: max-content;
  padding: 4px 6px;
  margin: 0 3px 0 0;
  line-height: 16px;
  font-size: 14px;
  color: #707a8a;
}

.borrow-sec ul li:hover,
.borrow-sec ul li.active {
  background: #eaecef;
  color: #000;
}

.borrow-tooltip-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid-inner-tab .cus-grid-tabs {
  width: calc(50% - 20px);
  /* height: 32px; */
  padding: 2px;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  min-height: 34px;
  margin-bottom: 16px;
}

.cus-grid-tabs .cus-grid-tab {
  /* background: red; */
  width: calc(100% / 3);
  min-height: 34px;
}

.cus-grid-tabs .cus-grid-tab.Mui-selected {
  background: #fff;
  color: #707a8a !important;
}

.tab-icons {
  width: 16px;
  height: 16px;
}

.cus-grid-tabs .MuiTabs-scroller {
  min-height: 34px;
}

.parameters-sec {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
}

.parameters-sec .parameters-text {
  font-size: 14px;
  font-family: "Poppins-Regular";
  line-height: 20px;
}
.time-period-sec {
  margin: 8px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.time-period-sec .sub-parameters-text {
  color: rgb(112, 122, 138);
  font-size: 12px;
  line-height: 16px;
}
.time-period-sec .time-per-text {
  border-bottom: 1px dashed rgb(112, 122, 138);
  max-width: max-content;
}
