* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  outline: none;
  text-decoration: none;
  color: inherit;
}
.mainHeader {
  width: 100%;
  z-index: 10002;
  font-family: "Nunito Sans", Arial, Helvetica, sans-serif;
}
.main-background {
  background-image: url("../images/png/Slider1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* display: flex; */
  height: 900px;
  width: 100%;
}
.exchange-topbar {
  /* background-color: #e4becb; */
  background: linear-gradient(72.47deg, #18c77763 22.16%, #3ca4b5d6 76.47%);
  color: #181818;
  display: flex;
  font-size: 0.813em;
  line-height: 3.077em;
  /* max-width: 1660px; */
}
.port {
  margin: auto;
  width: 100%;
  padding: 0 30px;
  /* max-width: 1660px; */
  display: flex;
  justify-content: space-between;
}
.header_Book_btn {
  padding-left: 10%;
}

.btIconWidgetIcon {
  color: #ffff;
  line-height: inherit;
  display: inline-block;
}
.btIconWidgetContent {
  margin-left: 0.5em;
  line-height: inherit;
  display: inline-block;
}
.btIconWidgetTitle {
  display: inline-block;
  line-height: inherit;
}
.btTopToolsRight {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
.bt-logo-area {
  position: relative;
  z-index: 1;
}

.menu-holder {
  display: flex;
}
.topBarInMenu {
  height: 110px;
  display: flex;
  align-items: center;
  line-height: inherit;
  float: right;
  margin-left: 500px;
}
.bt_bb_button {
  border: 0;
  font-size: 1em;
  margin-bottom: 0;
  display: inline-block;
  line-height: 1;
}
.bt_bb_button a {
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.bt_bb_link {
  box-shadow: 0 0 0 2px #e4becb inset;
  background-color: transparent;
  overflow: hidden;
  padding: 1.063em 1.563em;
}
.bt_bb_button,
.bt_bb_icon_position_left,
.bt_bb_button_text {
  color: #e4becb;
  white-space: nowrap;
  order: 2;
  font-weight: bold;
}
.menuPort nav ul {
  list-style: none;
  display: block;
}
/* ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
} */
nav {
  padding-left: 50px;
  float: left;
  color: #ffff;
}
.menuPort nav ul li {
  margin: 30px 20px 30px 0px;
  float: left;
  position: relative;
  z-index: 5;
}
.menuPort nav ul li a {
  line-height: calc(140px * 0.5);
}
.bt_bb_headline {
  color: white;
  text-align: center;
  font-size: 5rem;
  line-height: 1;
  letter-spacing: -0.03em;
  font-weight: 400;
  /* padding-top: 200px; */
  font-family: "Urbanist-Regular";
}
.bt_bb_subheadline {
  color: white;
  text-align: center;
  font-size: 1.25rem;
  padding-top: 0.75em;
  margin-top: 0;
  line-height: 1.5em;
  font-style: normal;
}
.solution {
  display: flex;
  font-size: 1.125em;
  float: none;
  margin-top: 40px;
}
.solution a {
  background-color: #481b35;
  display: flex;
  margin-left: 43%;
  box-shadow: none;
}
.solution .bt_bb_button_text {
  color: white;
}
.coverage_image {
  position: absolute;
  bottom: -2%;
  left: 0;
}
.coverage_image img {
  pointer-events: none;
  max-width: 100%;
}

.business-container {
  position: relative;
  height: 500px;
  width: 100%;
}

.heading-container {
  float: left;
  flex: 0 0 620.001px;
  max-width: 620.001px;
}
.heading-container h1 {
  font-size: 4.25rem;
  line-height: 1;
  letter-spacing: -0.03em;
  font-weight: bold;
  font-family: "Playfair Display";
}
.spacing-40 {
  height: 140px;
}
.buisness-analytics-heading h6 {
  font-weight: bold;
  letter-spacing: -0.03em;
  font-size: 1.563em;
  padding-bottom: 0.625em;
  line-height: 1;
}
.buisness-analytics-content p {
  text-align: left;
}
.content {
  padding: 0 0 0 1.25em;
  order: 2;
  text-align: left;
  flex: 1 1 auto;
}
.card {
  padding-top: 40px;
  padding-right: 90px;
}
.bottomEnd img {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 2;
  pointer-events: none;
  max-width: 100%;
}
.bottomEnd {
  margin-top: 20px;
}

.navigating-main {
  height: 500px;
  width: 100%;
  display: flex;
}
.image-container {
  height: 500px;
  width: 50%;
}
.navigation-content {
  display: flex;
  height: 500px;
  width: 50%;
  flex-direction: column;
}
.navigation-details h1 {
  font-size: 4.25rem;
  line-height: 1;
  letter-spacing: -0.03em;
  font-weight: bold;
  font-family: "Playfair Display";
}
.navigation-details {
  max-width: 620.001px;
  padding-top: 0.75em;
}
.navigation-details p {
  font-size: 1.125rem;
  line-height: 1.5em;
}
.case_studies {
  background-image: url("../images/png/Slider1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.case_studies_coverage {
  position: absolute;
  bottom: -65px;
  width: 100%;
}
.case_studies_coverage img {
  transform: rotateY(176deg);
  width: 100%;
}

.case-studies-main {
  display: flex;
  height: 50vh;
  height: 10vh;
  flex-direction: row;
  /* margin-top: 5%; */
  position: relative;
}
.case-studies-one {
  margin-top: 5%;
  height: 100vh;
  width: 25%;
}
.case-studies-two {
  /* margin-top: 20%; */
  /* height: 100vh;
  width: 50%; */
  flex: 1;
}
.case-studies-three {
  margin-top: 5%;
  height: 100vh;
  width: 25%;
}
.case-studies-heading-right {
  float: left;
}
.case-studies-heading {
  /* padding-top: 30%; */
  text-align: center;
}
.case-studies-heading h1 {
  font-family: "Playfair Display";
  font-weight: bolder;
  color: white;
}
.portfolio a {
  margin-left: 35%;
}

.grid-box-performance {
  padding-top: 40px;
  text-align: right;
}
.case-studies-performance h5 {
  color: white;
  font-weight: 500;
}
.case-studies-performance-content {
  color: white;
  padding-top: 20px;
  padding-left: 90px;
  line-height: 1.6;
}
.grid-box-right {
  padding-top: 40px;
  float: left;
}
.case-studies-right-heading h5 {
  color: white;
  font-weight: 500;
}
.case-studies-right-content {
  color: white;
  padding-top: 20px;
  padding-right: 90px;
  line-height: 1.6;
}
/* banneer css */

.banner_content {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  height: 100vh;
}
.main_banner {
  position: relative;
}

/* logo ajani */

.btMainLogo {
  width: 100%;
}

.logo {
  width: 200px;
}
.header_left_content {
  display: flex;
  align-items: center;
  height: 110px;
}
/* service images */
.service {
  display: flex;
  position: relative;
  justify-content: space-between;
}
.image_box img {
  width: 100%;
}

/* buy crypto */
.image-container-crypto img {
  width: 70%;
}
.image-container-crypto {
  padding-left: 10%;
  padding-top: 3%;
}

/* Himanshu changes */

/* .exchange-header .fbc-logo {
  width: 165px;
  height: 97px;
} */

.topBarInMenu .exchange-header-btn {
  background: #32b3c8;
  border-radius: 20px;
}

.topBarInMenu .exchange-header-btn:hover {
  background: #32b3c8;
}

.case_studies .case-studies-heading {
  padding-top: 10px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-size: 25px;
  font-family: "Playfair Display";
  font-weight: bolder;
}

.menuPort {
  width: 100%;
}

.head-per-bt .bt-logo-area {
  background-image: url("../images/png/Slider1.jpg");
  background-size: cover;
  background-position: center;
}

.exchange-wallet-btn {
  display: flex;
  align-items: center;
}

.exchange-header-btn .wallet-btn {
  background: #5ab2c0;
}

.exchange-wallet-btn .profile-name {
  margin-left: 0px !important;
}
