* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.market-main-box {
  margin: 24px;
}
.market-typography-box {
  width: 100%;
  padding: 0px 24px;
}
.market-typography-box .market-typography {
  color: #23262f;
  font-family: IBM Plex Sans SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
  white-space: nowrap;
}
.market-analysis-panel {
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-family: IBMPlexSans-Regular;
  justify-content: space-around;
  padding: 24px 0;
}
.market-section-title {
  padding: 10px 0;
}
.market-section-title .market-section-heading {
  margin-left: 2px;
  font-family: IBMPlexSans-Regular;
  font-weight: 600;
}
.market-hot-coins {
  margin-top: 20px;
  width: 300px;
  display: block !important;
}
.market-hot-coins-paper{
  padding: 6px 20px;
}
.market-hot-coins-list {
  width: 300px;
  display: block !important;
}
.market-hot-coins-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.market-img-box {
  font-size: 20px;
  width: 100%;
  flex: 0 0 70px;
  max-width: 70px;
  font-size: 13px;
  font-family: "Poppins-Regular";
  min-width: 95px;
  gap: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.market-name {
  font-size: 20px;
  width: 100%;
  flex: 0 0 58px;
  max-width: 58px;
  font-size: 13px;
  font-family: IBM Plex Sans SemiBold;
}
.market-dollar {
  color: gray;
  font-size: 20px;
  width: 100%;
  flex: 0 0 58px;
  max-width: 58px;
  font-size: 13px;
  font-family: "Poppins-Regular";
}
.market-percentage {
  /* color: green; */
  font-size: 20px;
  width: 100%;
  flex: 0 0 78px;
  max-width: 78px;
  font-size: 13px;
  font-family: "Poppins-Regular";
}
.market-typography-box .market-typography-paragraph {
  margin-top: 10px;
  line-height: 30px;
  font-size: 15px;
}
.market-section-heading .custom-table-th {
  width: 200px;
}
.table-img {
  height: 36px;
  width: 45px;
  object-fit: contain;
}
.search-flex {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  flex-direction: column;
  max-width: 300px;
  margin-left: auto;
  position: relative;
}
.search-flex input {
  border: 1px solid gray;
  padding: 10px;
}
.search-icon-img {
  position: absolute;
  left: 12px;
  top: 41px;
  height: 10px;
  width: 10px;
  max-width: 10px;
}
.custom-table th {
  color: #777e91;
  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
}
.custom-table thead tr:first-child th {
  background-color: #777e91;
}
.table-header th {
  color: #777e91;
  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
}
.form-group .form-control input {
  border: 1px solid #849ec1;
  width: 100%;
  padding: 11.45px 16px;
  background-color: transparent;
  border-radius: 14px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}
.search-grey-img {
  position: absolute;
  left: 13px;
  top: 10px;
  height: 13px;
  width: 10px;
  margin-right: 8px;
}
.looser-pesentage {
  color: red;
}
/* search */
.user-search-box {
  position: relative;
  margin-right: 10px;
}

.user-search-box .form-control input {
  padding: 6px 15px 6px 30px !important;
}

.user-search-box .form-control input::placeholder {
  color: #6b7280;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}
/* pagination */

.pagination-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;
  margin-right: 20px;
  margin-bottom: 5px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  margin: 0 4px;
}
.category-box-flex {
  display: flex;
  justify-content: space-between;
}
.market-tabs-main {
  overflow-x: scroll !important;
  margin-bottom: 20px;
  max-width: 1500px;
}

.coin-name {
  color: #777e90;
  font-family: "IBM Plex Sans SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin: 0px 12px 0px 0px;
}
.market-category-text .active {
  font-family: "DMSans-Bold";
  /* background-color: #572c0a; */
  background-color: #c99627;
  color: #fff;
}

.market-tabs-main .market-category-text {
  padding: 7px 11px;
  font-size: 15px;
  line-height: 14px;
  margin: 0px 10px 15px;
  font-family: "Poppins-Regular";
}
.sort-review-table {
  font-size: 15px !important;
  font-weight: 600;
  margin-right: 10px;
}
.market-table-body {
  display: flex;
  align-items: center;
}

.market-tabs-main-set {
  display: -webkit-box !important;
}
.market-typography-box .bnb-token-name{
  color: #1e2329;
  font-size: 29px;
  font-weight: 550;
  line-height: 48px;
  display: flex;
  align-items: center;
  margin-left: 25px;
}
.market-section-title .section-heading{
  align-items: center;
    color: #1e2329;
    display: flex;
    font-weight: 600;
    line-height: 48px;
    font-size: 20px;
}
.market-section-title .exchange-section-heading{
  font-size: 25px;
}
.market-title .bnb-token-name{
  text-align: center;
  color: #1e2329;
    font-size: 29px;
    font-weight: 550;
    line-height: 48px;
    /* display: flex; */
    align-items: center;
    margin-left: 22px;
}
.market-pagination .market-pagination-left{
padding: 0px;
}